var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav-tab-content-grid", [
    _c("div", { staticClass: "d-grid g-overview" }, [
      _c("div", { staticClass: "d-grid g-paper-title" }, [
        _c("h1", { domProps: { innerHTML: _vm._s(_vm.Title) } }),
        _vm._v(" "),
        _c("p", { staticClass: "small mb-0 text-paper-type" }, [
          _vm._v(
            "\n\t\t\t\t\t" +
              _vm._s(_vm.calcFormatAndDocumentOutput) +
              "\n\t\t\t\t"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-grid g-left" }, [
        _c("div", { staticClass: "d-grid g-details" }, [
          _c("h2", [_vm._v("Details")]),
          _vm._v(" "),
          _vm.StartDate
            ? _c("div", { staticClass: "field-label" }, [
                _vm._v(
                  "\n                        Start Date\n                    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.StartDate
            ? _c("div", { staticClass: "field-value" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.StartDate) +
                    "\n                    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.DueDate
            ? _c("div", { staticClass: "field-label" }, [
                _vm._v(
                  "\n                        Due Date\n                    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.DueDate
            ? _c("div", { staticClass: "field-value" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.DueDate) +
                    "\n                    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.legacyPaper.openObject.classID != 0
            ? _c("div", { staticClass: "field-label" }, [
                _vm._v("\n                        Class\n                    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.legacyPaper.openObject.classID != 0
            ? _c("div", { staticClass: "field-value" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$store.state.legacyPaper.openObject.className) +
                    "\n                    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.truncatedDescription
            ? _c("div", { staticClass: "field-label" }, [
                _vm._v(
                  "\n                        Description\n                    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.truncatedDescription
            ? _c(
                "div",
                {
                  staticClass: "field-value",
                  attrs: { title: _vm.Description },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.truncatedDescription) +
                      "\n                    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "g-row" }, [
            _c("div", { staticClass: "g-col" }, [
              _vm.isComplete
                ? _c(
                    "button",
                    {
                      key: "btnMarkIncomplete",
                      staticClass: "btn btn-outline-success",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.togglePaperComplete.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t\tMark Unfinished\n\t\t\t\t\t\t\t\t"
                      ),
                    ]
                  )
                : _c(
                    "button",
                    {
                      key: "btnMarkComplete",
                      staticClass: "btn btn-success",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.togglePaperComplete.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t\tMark Paper Finished\n\t\t\t\t\t\t\t\t"
                      ),
                    ]
                  ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-grid g-right" }, [_c("writing-tips")], 1),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }