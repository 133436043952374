var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rb-wrp clearfix" }, [
    _c("div", { staticClass: "rb float-start" }, [
      _c(
        "a",
        {
          staticClass: "rb-btn",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.btnDownloadWordDoc.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "rb-icon rb-icon-download-word-doc" }),
          _vm._v("\n\t\t\tDownload"),
          _c("br"),
          _vm._v("\n\t\t\tWord Doc\n\t\t"),
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "rb-btn",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.btnDownloadPdf.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "rb-icon rb-icon-download-pdf" }),
          _vm._v("\n\t\t\tDownload"),
          _c("br"),
          _vm._v("\n\t\t\tPDF\n\t\t"),
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "rb-btn",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.btnPrint.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "rb-icon rb-icon-print" }),
          _vm._v(" "),
          _c("br"),
          _vm._v("\n\t\t\tPrint\n\t\t"),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }