var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isSplit
      ? _c(
          "div",
          { key: "divSplitRow", staticClass: "form-display row mb-3" },
          [
            _c("div", { staticClass: "col-label" }, [
              _c("label", {
                class: [
                  "form-label form-label-v2",
                  { "fst-italic": _vm.isInvalidShown },
                ],
                attrs: { for: _vm.safeId },
                domProps: { innerHTML: _vm._s(_vm.calcLabelOutput) },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-value" }, [
              _c(
                "select",
                {
                  class: ["form-select", { "is-invalid": _vm.isInvalidShown }],
                  attrs: { id: _vm.safeId },
                  domProps: { value: _vm.value },
                  on: {
                    change: function ($event) {
                      return _vm.selectChange($event.target.value)
                    },
                  },
                },
                _vm._l(_vm.calcSelectObject, function (singleObject, key) {
                  return _c(
                    "option",
                    {
                      key: key,
                      attrs: { disabled: singleObject.isDisabled },
                      domProps: { value: singleObject.value },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(singleObject.name) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.isInvalidShown
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.invalidErrorMessage) +
                        "\n\t\t\t"
                    ),
                  ])
                : _vm._e(),
            ]),
          ]
        )
      : _c("div", { key: "divFullRow", staticClass: "form-group" }, [
          _vm.label
            ? _c("label", {
                class: [{ "fst-italic": _vm.isInvalidShown }],
                attrs: { for: _vm.safeId },
                domProps: { innerHTML: _vm._s(_vm.calcLabelOutput) },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "select",
            {
              class: ["form-select", { "is-invalid": _vm.isInvalidShown }],
              attrs: { id: _vm.safeId },
              domProps: { value: _vm.value },
              on: {
                change: function ($event) {
                  return _vm.selectChange($event.target.value)
                },
              },
            },
            _vm._l(_vm.calcSelectObject, function (singleObject, key) {
              return _c(
                "option",
                {
                  key: key,
                  attrs: { disabled: singleObject.isDisabled },
                  domProps: { value: singleObject.value },
                },
                [_vm._v("\n\t\t\t\t" + _vm._s(singleObject.name) + "\n\t\t\t")]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.isInvalidShown
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v("\n\t\t\t" + _vm._s(_vm.invalidErrorMessage) + "\n\t\t"),
              ])
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }