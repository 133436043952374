<template>
	<nav-tab-content-grid>
		<div class="d-grid g-overview">
			<div class="d-grid g-paper-title">
				<h1 v-html="Title" />
				<p class="small mb-0 text-paper-type">
					{{ calcFormatAndDocumentOutput }}
				</p>
			</div><!--g-paper-title-->

			<div class="d-grid g-left">
				<!-- Paper Details -->
                <div class="d-grid g-details">
                    <h2>Details</h2>	

                    <div class="field-label" v-if="StartDate">
                        Start Date
                    </div>
                    <div class="field-value" v-if="StartDate">
                        {{ StartDate }}
                    </div>
                    <div class="field-label" v-if="DueDate">
                        Due Date
                    </div>
                    <div class="field-value" v-if="DueDate">
                        {{ DueDate }}
                    </div>
                    
                    <div class="field-label" v-if="$store.state.legacyPaper.openObject.classID != 0">
                        Class
                    </div>
                    <div class="field-value" v-if="$store.state.legacyPaper.openObject.classID != 0">
                        {{ $store.state.legacyPaper.openObject.className }}
                    </div>
                    
                    <div class="field-label" v-if="truncatedDescription">
                        Description
                    </div>
                    <div class="field-value" v-if="truncatedDescription" :title="Description">
                        {{ truncatedDescription }}
                    </div>

                    <div class="g-row">
                        <div class="g-col">
                            <!-- <div class="d-grid"> -->
                                <button v-if="isComplete" type="button" class="btn btn-outline-success" @click.prevent="togglePaperComplete" key="btnMarkIncomplete">
									Mark Unfinished
								</button>
								<button v-else type="button" class="btn btn-success" @click.prevent="togglePaperComplete" key="btnMarkComplete">
									Mark Paper Finished
								</button>
                            <!-- </div> -->
                        </div>
                    </div>
                    
                </div><!--d-grid g-details -->
			</div><!--g-left-->

			<div class="d-grid g-right">
				<writing-tips />
                <!-- <div class="alert alert-info">
				    <p>WE DON'T HAVE PAPER SAVE/RESTORE FOR LEGACY PAPERS</p>
                </div> -->
			</div><!--g-right-->

		</div><!--g-overview-->
		
	</nav-tab-content-grid>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import dateToLongString from '@/helpers/date-to-long-string';
import NavTabContentGrid from '@/components/NavTabContentGrid';
import ParseDocFormatType from '@/helpers/parse-doc-format-type';
// import RecentPaperSaves from './TabOverview/RecentPaperSaves';
import WritingTips from '@/components/WritingTips';

export default {
	name: 'TabOverview',
	data() {
		return {
			isComplete: false,
		}
	},
	computed:{
		calcFormatAndDocumentOutput(){
			return ParseDocFormatType({
				paperFormatVersionID: this.$store.state.legacyPaper.openObject.paperFormatVersionID,
				paperTypeEnum: this.$store.state.legacyPaper.openObject.paperType,
			});
		},
        Description(){
            return this.$store.state.legacyPaper.openObject.description;
        },
        DueDate(){
			// comes in as the default for some reason, so just check for that value to skip
			if(this.$store.state.legacyPaper.openObject.dueDate != '' && this.$store.state.legacyPaper.openObject.dueDate !== '01/01/2099'){
				return dateToLongString({
					dateValue: this.$store.state.legacyPaper.openObject.dueDate,
					forceYear: true,
					showTime: false,
				});
			}
			return '';
		},
        StartDate(){
			if(this.$store.state.legacyPaper.openObject.startDate != ''){
				return dateToLongString({
					dateValue: this.$store.state.legacyPaper.openObject.startDate,
					forceYear: true,
					showTime: false,
				});
			}
			return '';
		},
		Title(){
			return this.$store.state.legacyPaper.openObject.title;
		},
        truncatedDescription(){
            if(this.Description){
                if(this.$store.state.legacyPaper.openObject.description.length <= 100){
                    return this.$store.state.legacyPaper.openObject.description;
                }
                return this.$store.state.legacyPaper.openObject.description.slice(0, 100) + '...';
            }
		},
		
	},
	methods:{
		togglePaperComplete(){
			this.isComplete = !this.isComplete;

			ApiS_MakeCall({
				method: 'GET',
				params:{
					'isComplete': this.isComplete,
					'paperId': this.$store.state.legacyPaper.openObject.paperID
				},
				url: 'v3/MarkPaperComplete',
			
			});//e:ApiS_MakeCall


		},//e:togglePaperComplete
	},
	mounted(){
		// a fileUrl hasn't been set yet so i should go back to the preview that that loads them
		if(this.$store.state.legacyPaper.fileUrl === ''){
			
			this.$router.push({
				name:'LegacyPaperTabPreview'
			}).catch(()=>{});
		}
		this.isComplete = this.$store.state.legacyPaper.openObject.isComplete;
	},
	components:{
		NavTabContentGrid,
		// PaperDetails,
		// PaperPlan,
		// RecentPaperSaves,
		WritingTips,
	},
	
}

</script>

<style lang="scss" scoped>
	@import "../../assets/styles/_vars_perrla.scss";

	@include color-mode(dark) {
		.text-paper-type {
			color: $gray-300;
		}
	}
    
	.g-overview {
		align-items: start;
		align-self:stretch;
		grid-column-gap: 3rem;
		grid-template-columns: 0.5fr 0.5fr 1fr 1fr;
		grid-template-rows: min-content auto;
		row-gap: 2rem;
		.g-paper-title {
			grid-column: 1 / span 4;
			grid-row: 1;
		}
		.text-paper-type {
			color: gray-700;
		}

		.g-left {
			grid-column: 1 / span 2;
			grid-row: 2;
		}
		.g-right {
			grid-column: 3 / span 2;
			grid-row: 2;
		}

        .g-details {
            grid-column: 1 / span 2;
            grid-column-gap: 1rem;
            grid-row: 2;
            grid-row-gap: 1rem;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: auto;
            align-self: start;
            margin-bottom: 2rem;

            h2{
                grid-column-start: span 3;
                grid-row: 1;
            }

            .field-label, .field-value {
                display: grid;
                font-size: 1rem;
                line-height: 1.2rem;
                margin-bottom: 0.25rem;
            }

            .field-label {
                grid-column: 1;
                font-weight: $font-weight-normal;
            }
            .field-value {
                grid-column: 2 / span 2;
                font-weight: $font-weight-light;
            }

            .g-row{
                display: grid;
                grid-template-columns: 1fr;
                grid-column-gap: 1rem;
                grid-column: 1 / span 3;
                justify-self: stretch;
                
                .g-col {
                    display: grid;
                    // grid-column: 1;

                }
            }
        }//.g-details 

	}//.g-overview

</style>