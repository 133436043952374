
import createPersistedState from 'vuex-persistedstate'; //https://github.com/robinvdvleuten/vuex-persistedstate
import Vue from 'vue';
import Vuex from 'vuex';

// store modules
import api from './modules/api';
import app from './modules/app';
import cart from './modules/cart';
import classes from './modules/classes';
import customer from './modules/customer';
import forms from './modules/forms'
import freeTrial from './modules/freeTrial';
import gridSorts from './modules/gridSorts';
import legacyPaper from './modules/legacyPaper';
import loaders from './modules/loaders';
import logs from './modules/logs';
import modals from './modules/modals';
import paperEdit from './modules/paperEdit';
import paperList from './modules/paperList';
import referenceDuplicate from './modules/referenceDuplicate';
import referenceEdit from './modules/referenceEdit';
import referenceRules from './modules/referenceRules';
import references from './modules/references';
import reminders from './modules/reminders';
import researchNotes from './modules/researchNotes';
import sunset from './modules/sunset';
import thirdPartyLookup from './modules/thirdPartyLookup';
import work from './modules/work';

Vue.use(Vuex);

export default new Vuex.Store({
	strict: process.env.NODE_ENV !== 'production',

	plugins: [createPersistedState({
		key: 'perrla_online',
		paths: [
			'api.sessionId',
			'api.token',
			'app.showAlphaFeatures',
			'app.showBetaFeatures',
			'customer.email',
			'customer.fullName',
			'customer.themeId',
			'gridSorts.addFromLibraryIsByName',
			'gridSorts.classListOrderBy',
			'gridSorts.classListSorts',
			'gridSorts.paperListOrderBy',
			'gridSorts.paperListSorts',
			'gridSorts.referenceListOrderBy',
			'gridSorts.referenceListSorts',
			'hasSeenTestServerAlert',
			'lastReloadDate',
			'lastSyncDate',
			'paperEdit.openReferenceUID',
			'paperEdit.ck.isWproofReaderActive',
			'paperEdit.outline.activeNodes',
			'paperEdit.researchNotes.activeOutlineResearchNotes',
			'paperEdit.researchNotes.activeResearchNotes',
			'paperEdit.toolDrawer.openWidth',
			'paperList.list',
			'refLibCompString',
			'refLibCompStringGenesis',
			'references.recentTypeIds',
			'researchNotes.list',
		]
	})],

	modules: {
		api,
		app,
		cart,
		classes,
		customer,
		forms,
		freeTrial,
		gridSorts,
		legacyPaper,
		loaders,
		logs,
		modals,
		paperEdit,
		paperList,
		referenceDuplicate,
		referenceEdit,
		referenceRules,
		references,
		reminders,
		researchNotes,
		sunset,
		thirdPartyLookup,
		work,
	},
	
	state: {
		// local storage states
		hasSeenTestServerAlert: false,
		lastReloadDate: '',	// used to force reload the SPA
		lastSyncDate: '', // returns from /Sync and is sent to back when new data is needed 
		refLibCompString: '',	// compressed LZ-String of referenceLibrary array
		refLibCompStringGenesis: '',	// compressed LZ-String of referenceLibraryGenesis array
		
		// non local storage states
		redirectToAfterLogin: '',	// a route string - you'll have to parse the params if you need them
		referenceLibrary: [],
		referenceLibraryGenesis: [],
		referenceTypeSearchTerm: '',	// create ref step 1 > all ref types > search term
		show3pluRedirectMessage: false,
		
	}, //e:state

	mutations: {
		ADD_RESEARCH_NOTE_TO_REFERENCE(state, $opts){
			state.referenceLibraryGenesis[$opts.referenceIndex].researchNotes.push({
				citation: '',
				genesisReferenceID: state.referenceLibraryGenesis[$opts.referenceIndex].referenceID,
				genesisResearchNoteID: $opts.genesisResearchNoteID,
				genesisResearchNoteUniqueID: $opts.genesisResearchNoteUniqueID,
				isDeleted: false,
				note: $opts.note,
			});
		},
		LAST_RELOAD_DATE(state, value) {
			state.lastReloadDate = value;
		},
		LAST_SYNC_DATE(state, value) {
			state.lastSyncDate = value;
		},
		REFERENCE_ADD_TO_LIBRARY(state, reference){
			state.referenceLibraryGenesis.push(reference);
		},
		REFERENCE_TYPE_SEARCH_TERM_SET(state, value) {
			state.referenceTypeSearchTerm = value;
		},
		REFERENCE_LIBRARY(state, value) {
			state.referenceLibrary = value;
		},
		REFERENCE_LIBRARY_COMPRESSED_STRING(state, value) {
			state.refLibCompString = value;
		},
		REFERENCE_LIBRARY_GENESIS(state, value) {
			state.referenceLibraryGenesis = value;
		},
		REFERENCE_LIBRARY_GENESIS_COMPRESSED_STRING(state, value) {
			state.refLibCompStringGenesis = value;
		},
		REFERENCE_REMOVE_FROM_LIBRARY(state, referenceUniqueID){
			// Start by looking in Genesis 
			let thisGenesisReferenceFindIndex = state.referenceLibraryGenesis.findIndex((reference)=>{
				return reference.referenceUniqueID.toUpperCase() == referenceUniqueID.toUpperCase();
			});

			if(thisGenesisReferenceFindIndex != -1){
				state.referenceLibraryGenesis.splice(thisGenesisReferenceFindIndex,1);
			} else {
				// Not found, now look in the Non Genesis library
				let thisNonGenesisReferenceFindIndex = state.referenceLibrary.findIndex((reference)=>{
					return reference.referenceUniqueID.toUpperCase() == referenceUniqueID.toUpperCase();
				});
				if(thisNonGenesisReferenceFindIndex != -1){
					state.referenceLibrary.splice(thisNonGenesisReferenceFindIndex,1);
				}
			}
		},
		REFERENCE_UPDATE(state, $opts){
			// Find this reference index
			let thisReferenceFindIndex = state.referenceLibraryGenesis.findIndex((reference)=>{
				return reference.referenceUniqueID.toUpperCase() == $opts.referenceUniqueID.toUpperCase()
			});
			// Replace Reference Data
			if(thisReferenceFindIndex != -1){
				state.referenceLibraryGenesis[thisReferenceFindIndex] = $opts.newReferenceData;
			}
		},
		SET_HAS_SEEN_TEST_SERVER_ALERT(state, value) {
			state.hasSeenTestServerAlert = value;
		},
		SET_REDIRECT_TO_AFTER_LOGIN(state, value) {
			state.redirectToAfterLogin = value;
		},
		SET_SHOW_3PLU_REDIRECT_MESSAGE(state, value) {
			state.show3pluRedirectMessage = value;
		},
	}, //e:mutations
});