<!--
- PERRLA Toolbar
-- repalce the shared space ckEditor toolbar
-- used in the Document Editor, Document Outline, Footnote Editor
-->

<template>
	<div>
		<!-- Editor Top -->
		<div class="toolbar-row" v-if="editorType === 'document'">
			<button class="" type="button" @click.prevent="execCommand('saveDocument')" title="Save Document" data-bs-placement="top" data-bs-toggle="tooltip" data-bs-title="Save Document">
				<span class="svg icons8-save" />
			</button>
			<button class="" type="button" @click.prevent="execCommand('printDocument')" title="Print Document" data-bs-placement="top" data-bs-toggle="tooltip" data-bs-title="Print Document">
				<span class="svg icons8-print" />
			</button>
			<div class="divider" />

			<div class="dropdown" data-bs-placement="top" data-bs-toggle="tooltip" data-bs-title="Document Font">
				<a href="#" class="dropdown-toggle" type="button" role="button" data-bs-toggle="dropdown" aria-expanded="false" title="Document Font">
					{{ activeFont }}
				</a>
				<ul class="dropdown-menu" aria-labelledby="dropdownMenuFont">
					<li>
						<a :class="['dropdown-item hover hover-primary', {'active': $store.state.paperEdit.meta.FontFamily == $enums.FontFamily.ARIAL }]" class="dropdown-item hover hover-primary" href="#" @click.prevent="applyFont('ARIAL')" style="font-family: Arial, Helvetica, sans-serif;">
							Arial
						</a>
					</li>
					<li>
						<a :class="['dropdown-item hover hover-primary', {'active': $store.state.paperEdit.meta.FontFamily == $enums.FontFamily.COURIER }]" href="#" @click.prevent="applyFont('COURIER')" style="font-family: CourierNew, Courier, monospace;">
							Courier
						</a>
					</li>
					<li>
						<a :class="['dropdown-item hover hover-primary', {'active': $store.state.paperEdit.meta.FontFamily == $enums.FontFamily.TIMES_NEW_ROMAN }]" href="#" @click.prevent="applyFont('TIMES_NEW_ROMAN')" style="font-family: TimesNewRoman, Times, serif;">
							Times New Roman
						</a>
					</li>
					<li>
						<a :class="['dropdown-item hover hover-primary', {'active': $store.state.paperEdit.meta.FontFamily == $enums.FontFamily.TREBUCHET }]" href="#" @click.prevent="applyFont('TREBUCHET')" style="font-family: TrebuchetMS, LucidaSansUnicode, LucidaGrande, LucidaSans, Arial, sans-serif;">
							Trebuchet
						</a>
					</li>
					<li>
						<a :class="['dropdown-item hover hover-primary', {'active': $store.state.paperEdit.meta.FontFamily == $enums.FontFamily.VERDANA }]" href="#" @click.prevent="applyFont('VERDANA')" style="font-family: Verdana, Geneva, Tahoma, sans-serif;">
							Verdana
						</a>
					</li>
				</ul>
			</div>
		</div>

		<!-- Editor Bottom -->
		<div class="toolbar-row" v-if="editorType === 'document'">
			<button class="" type="button" @click.prevent="execCommand('bold')" :id="'toolbar_' + $enums.CkEditorType.BODY + '_bold'" title="Bold" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Bold">
				<span class="svg bi bi-type-bold" />
			</button>
			<button class="" type="button" @click.prevent="execCommand('italic')" :id="'toolbar_' + $enums.CkEditorType.BODY + '_italic'" title="Italic" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Italic">
				<span class="svg bi bi-type-italic" />
			</button>
			<button class="" type="button" @click.prevent="execCommand('underline')" :id="'toolbar_' + $enums.CkEditorType.BODY + '_underline'" title="Underline" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Underline">
				<span class="svg bi bi-type-underline" />
			</button>
			
			<div class="divider" />

			<button class="btn btn-sm" type="button" @click.prevent="execCommand('numberedlist')" :id="'toolbar_' + $enums.CkEditorType.BODY + '_numberedlist'" title="Insert/Remove Numbered List" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Numbered list">
				<span class="svg bi bi-list-ol" />
			</button>
			<button class="btn btn-sm" type="button" @click.prevent="execCommand('bulletedlist')" :id="'toolbar_' + $enums.CkEditorType.BODY + '_bulletedlist'" title="Insert/Remove Bulleted List" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Bulleted List">
				<span class="svg bi bi-list-ul" />
			</button>

			<button class="btn btn-sm btn-flip" type="button" @click.prevent="execCommand('outdent')" :id="'toolbar_' + $enums.CkEditorType.BODY + '_outdentblock'" title="Decrease Indent" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Decrease Indent">
				<span class="svg icons8-outdent" />
			</button>

			<button class="btn btn-sm btn-flip" type="button" @click.prevent="btnExecIndent" :id="'toolbar_' + $enums.CkEditorType.BODY + '_indentblock'" title="Increase Indent" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Increase Indent">
				<span class="svg icons8-indent" />
			</button>

			<div class="divider" />

			<button class="btn btn-sm" type="button" @click.prevent="execCommand('subscript')" :id="'toolbar_' + $enums.CkEditorType.BODY + '_subscript'" title="Subscript" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Subscript">
				<span class="svg icons8-subscript" />
			</button>
			<button class="btn btn-sm" type="button" @click.prevent="execCommand('superscript')" :id="'toolbar_' + $enums.CkEditorType.BODY + '_superscript'" title="Superscript" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Superscript">
				<span class="svg icons8-superscript" />
			</button>
		</div>


		<!-- Outline Top -->
		<div class="toolbar-row" v-if="editorType === 'outline'">
			<button class="" type="button" @click.prevent="execCommand('saveOutline')" title="Save Outline" data-bs-placement="top" data-bs-toggle="tooltip" data-bs-title="Save Outline">
				<span class="svg icons8-save" />
			</button>
			<button class="" type="button" @click.prevent="execCommand('printDocument')" title="Print Document" data-bs-placement="top" data-bs-toggle="tooltip" data-bs-title="Print Document">
				<span class="svg icons8-print" />
			</button>
			<div class="divider" />

			<div class="dropdown" data-bs-placement="top" data-bs-toggle="tooltip" data-bs-title="Document Font">
				<a href="#" class="dropdown-toggle" type="button" role="button" data-bs-toggle="dropdown" aria-expanded="false" title="Document Font">
					{{ activeFont }}
				</a>
				<ul class="dropdown-menu" aria-labelledby="dropdownMenuFont">
					<li>
						<a :class="['dropdown-item hover hover-primary', {'active': $store.state.paperEdit.meta.FontFamily == $enums.FontFamily.ARIAL }]" class="dropdown-item hover hover-primary" href="#" @click.prevent="applyFont('ARIAL')" style="font-family: Arial, Helvetica, sans-serif;">
							Arial
						</a>
					</li>
					<li>
						<a :class="['dropdown-item hover hover-primary', {'active': $store.state.paperEdit.meta.FontFamily == $enums.FontFamily.COURIER }]" href="#" @click.prevent="applyFont('COURIER')" style="font-family: CourierNew, Courier, monospace;">
							Courier
						</a>
					</li>
					<li>
						<a :class="['dropdown-item hover hover-primary', {'active': $store.state.paperEdit.meta.FontFamily == $enums.FontFamily.TIMES_NEW_ROMAN }]" href="#" @click.prevent="applyFont('TIMES_NEW_ROMAN')" style="font-family: TimesNewRoman, Times, serif;">
							Times New Roman
						</a>
					</li>
					<li>
						<a :class="['dropdown-item hover hover-primary', {'active': $store.state.paperEdit.meta.FontFamily == $enums.FontFamily.TREBUCHET }]" href="#" @click.prevent="applyFont('TREBUCHET')" style="font-family: TrebuchetMS, LucidaSansUnicode, LucidaGrande, LucidaSans, Arial, sans-serif;">
							Trebuchet
						</a>
					</li>
					<li>
						<a :class="['dropdown-item hover hover-primary', {'active': $store.state.paperEdit.meta.FontFamily == $enums.FontFamily.VERDANA }]" href="#" @click.prevent="applyFont('VERDANA')" style="font-family: Verdana, Geneva, Tahoma, sans-serif;">
							Verdana
						</a>
					</li>
				</ul>
			</div>
			
			<div v-if="!$isLive || $store.state.customer.isImpersonating" class="divider" />
			
			<button v-if="!$isLive || $store.state.customer.isImpersonating" class="btn btn-sm btn-source" type="button" @click.prevent="execCommand('source')" :id="'toolbar_' + $enums.CkEditorType.OUTLINE + '_indentblock'" title="Increase Indent">
				SRC
			</button>
		</div>

		<!-- Outline Bottom -->
		<div class="toolbar-row" v-if="editorType === 'outline'">
			<button class="" type="button" @click.prevent="execCommand('bold')" :id="'toolbar_' + $enums.CkEditorType.OUTLINE + '_bold'" title="Bold" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Bold">
				<span class="svg bi bi-type-bold" />
			</button>
			<button class="" type="button" @click.prevent="execCommand('italic')" :id="'toolbar_' + $enums.CkEditorType.OUTLINE + '_italic'" title="Italic" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Italic">
				<span class="svg bi bi-type-italic" />
			</button>
			<button class="" type="button" @click.prevent="execCommand('underline')" :id="'toolbar_' + $enums.CkEditorType.OUTLINE + '_underline'" title="Underline" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Underline">
				<span class="svg bi bi-type-underline" />
			</button>
			
			<div class="divider" />

			<button class="btn btn-sm" type="button" @click.prevent="execCommand('numberedlist')" :id="'toolbar_' + $enums.CkEditorType.OUTLINE + '_numberedlist'" title="Insert/Remove Numbered List" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Numbered list">
				<span class="svg bi bi-list-ol" />
			</button>
			<button class="btn btn-sm" type="button" @click.prevent="execCommand('bulletedlist')" :id="'toolbar_' + $enums.CkEditorType.OUTLINE + '_bulletedlist'" title="Insert/Remove Bulleted List" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Bulleted List">
				<span class="svg bi bi-list-ul" />
			</button>

			<button class="btn btn-sm btn-flip" type="button" @click.prevent="execCommand('outdent')" :id="'toolbar_' + $enums.CkEditorType.OUTLINE + '_outdentblock'" title="Decrease Indent" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Decrease Indent">
				<span class="svg icons8-outdent" />
			</button>

			<button class="btn btn-sm btn-flip" type="button" @click.prevent="execCommand('indent')" :id="'toolbar_' + $enums.CkEditorType.OUTLINE + '_indentblock'" title="Increase Indent" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Increase Indent">
				<span class="svg icons8-indent" />
			</button>

			<div class="divider" />

			<button class="btn btn-sm" type="button" @click.prevent="execCommand('subscript')" :id="'toolbar_' + $enums.CkEditorType.OUTLINE + '_subscript'" title="Subscript" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Subscript">
				<span class="svg icons8-subscript" />
			</button>
			<button class="btn btn-sm" type="button" @click.prevent="execCommand('superscript')" :id="'toolbar_' + $enums.CkEditorType.OUTLINE + '_superscript'" title="Superscript" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Superscript">
				<span class="svg icons8-superscript" />
			</button>
		</div>

		<!-- Footnote -->
		<div class="toolbar-row" v-if="editorType === 'footnote'">
			<button class="" type="button" @click.prevent="execCommand('saveFootnotes')" title="Save Footnote" data-bs-placement="top" data-bs-toggle="tooltip" data-bs-title="Save Footnote">
				<span class="svg icons8-save" />
			</button>

			<div class="divider" />
			
			<button class="" type="button" @click.prevent="execCommand('bold')" :id="'toolbar_' + $enums.CkEditorType.FOOTNOTES + '_bold'" title="Bold" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Bold">
				<span class="svg bi bi-type-bold" />
			</button>
			<button class="" type="button" @click.prevent="execCommand('italic')" :id="'toolbar_' + $enums.CkEditorType.FOOTNOTES + '_italic'" title="Italic" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Italic">
				<span class="svg bi bi-type-italic" />
			</button>
			<button class="" type="button" @click.prevent="execCommand('underline')" :id="'toolbar_' + $enums.CkEditorType.FOOTNOTES + '_underline'" title="Underline" data-bs-placement="bottom" data-bs-toggle="tooltip" data-bs-title="Underline">
				<span class="svg bi bi-type-underline" />
			</button>

		</div>
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import config from '@/config';

export default {
	name: 'CkToolbar',
	props:{
		editorType:{
			required: true,
			type: String,
		},
	},
	computed:{
		activeFont(){
			switch(this.$store.state.paperEdit.meta.FontFamily){
				case config.enums.FontFamily.ARIAL:
					return 'Arial';
				case config.enums.FontFamily.COURIER:
					return 'Courier';
				case config.enums.FontFamily.TREBUCHET:
					return 'Trebuchet';
				case config.enums.FontFamily.VERDANA:
					return 'Verdana';
				default:
					return 'Times New Roman';
			}
		},
	},
	methods: {
		applyFont(fontEnumName){
			if(_has(config.enums.FontFamily), fontEnumName){
				window.$vm.$store.commit('paperEdit/meta/SET_FontFamily', config.enums.FontFamily[fontEnumName]);
				
				// loop through all active ckeditor instances
				_forEach(CKEDITOR.instances, (ckInstance)=>{
					ckInstance.document.$.body.classList.remove('font-family-arial', 'font-family-courier', 'font-family-times-new-roman', 'font-family-trebuchet', 'font-family-verdana');
					ckInstance.document.$.body.classList.add('font-family-' + fontEnumName.replace(/_/g, '-').toLowerCase());
				});
			}

		},
		btnExecIndent(){
			let editor = CKEDITOR.instances[this.$store.state.paperEdit.ck.editorLastFocused];
			
			if(editor && editor.$ckEditorType !== config.enums.CkEditorType.ANNOTATION){
				editor.execCommand('indent');
			}

		},
		execCommand(commandName){
			let editor;
			switch(this.editorType){
				case 'document':
					editor = CKEDITOR.instances[this.$store.state.paperEdit.ck.editorLastFocused];
					break;
				case 'outline':
					editor = CKEDITOR.instances.ckPaperOutline;
					break;	
				case 'footnote':
					editor = CKEDITOR.instances.ckPaperFootnotes;
					break;
			}
			if(editor){
				editor.execCommand(commandName);
			}
		},

	},
}
</script>

<style lang="scss" scoped>
	@import "../assets/styles/_vars_perrla.scss";
	$toolbar-block-height: 31px;

	@include color-mode(dark) {
		.toolbar-row{
			button {
				background-color: $gray-800;
				color: $dark-body-color;
				
				&:hover {
					background: $gray-700;
				}
				&.btn-active, &:active  {
					background: $gray-500;
					color: $body-color;
				}
				&.btn-disabled {
					color: $gray-700;
				}
			}

			div.dropdown {
				a.dropdown-toggle{
					color: $dark-body-color;

					&:hover {
						background: $gray-700;
					}
				}
			}
		}
	}

	.toolbar-row{
		display: flex;

		button {
			background-color: $gray-200;
			border: none;
			border-radius: $btn-border-radius;
			color: $body-color;
			font-size: 1.25rem;
			font-weight: $font-weight-bold;
			padding: 0.125rem 0.25rem;
			text-align:center;
			text-decoration: none;

			&:hover {
				background: $gray-400;
			}
			&.btn-active {
				background: $gray-500;
			}
			&.btn-disabled {
				color: $gray-500;
				cursor: not-allowed;
				&:hover {
					background-color: $gray-200;
				}
			}
			&.btn-flip{
				transform: scaleX(-1);
			}
			
			&.btn-source{
				font-size: 0.666rem;
			}
		}

		div.dropdown {
			a.dropdown-toggle{
				color: $body-color;
				font-size: 0.75rem;
				line-height: $toolbar-block-height;
				padding: 0 0.5rem;
				text-decoration: none;

				// caret
				// &::after {
				// }
				
				&:hover {
					background: $gray-400;
				}
				
			}
		}

		div.divider{
			background: $gray-500;
			display: block;
			height: calc(#{$toolbar-block-height} - 12px);
			margin: 6px 3px 0 3px;
			width:1px;
		}
	}

</style>
