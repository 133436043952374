<!--
- Navigation Tabs
-- Row of one or more Nav Tabs
-- these are the tabs of the admin site - with an underline
-->

<template>
	<ul class="nav nav-tabs nav-tabs-module d-print-none">
		<slot />
	</ul>
</template>

<script>
export default {
	name: 'NavTabs',
}
</script>
