<!--
- Papers > List
-- sort & search grid
-- each row is a document
-->

<template>
	<div>
		<div class="d-flex flex-row mb-2">
			<h1 class="float-start me-3 mb-0">
				Papers
			</h1>

			<div class="dropdown float-start me-3">
				<button class="btn dropdown-toggle btn-success" type="button" data-bs-toggle="dropdown" aria-expanded="false">
					<span class="svg bi-plus-lg me-1" /> Create a new
				</button>
				<ul class="dropdown-menu">
					<li>
						<a class="dropdown-item hover hover-success" href="#" @click.prevent="btnOpenCreateModalResearchPaper">
							Research Paper
						</a>
					</li>
					<li>
						<a class="dropdown-item hover hover-success" href="#" @click.prevent="btnOpenCreateModalDiscussionPost">
							Discussion Post
						</a>
					</li>
					<li>
						<a class="dropdown-item hover hover-success" href="#" @click.prevent="btnOpenCreateModalReferenceList">
							Reference List
						</a>
					</li>
					<li>
						<a class="dropdown-item hover hover-success" href="#" @click.prevent="btnOpenCreateModalAnnotatedBibliography">
							Annotated Bibliography
						</a>
					</li>
				</ul>
			</div><!--dropdown-->

			<button v-if="$store.state.app.showBetaFeatures" class="float-start me-3 btn btn-outline-warning" title="Rebuild Papers" @click.prevent="btnRebuildGrid" type="button">
				<span class="svg icons8-undo" />
			</button>
			
			<grid-search
				class="ms-auto col-4"
				placeholder-text="Search Papers"
				@update-grid="calcGridData" />
			
		</div><!--flex-row-->

		<div class="row">
			<div class="col-12 min-height overflow-y-auto position-relative" ref="divPaperListWrp">
				<table v-if="filteredGridData && filteredGridData.length > 0" class="table table-borderless entity-grid table-fixed-header" key="tableHasPaperData">
					<thead>
						<tr>
							<sortable-grid-th
								cell-text="Title"
								colspan="2"
								sort-key="title"
								:entity-id="entityId" 
								:is-local-storage="true"
								:is-title="true"
								@update-grid="calcGridData" />

							<th class="table-spacer" />

							<th>Type</th>

							<th class="table-spacer" />

							<th>Class</th>

							<th class="table-spacer" />

							<sortable-grid-th 
								cell-text="Due Date" 
								sort-key="dueDateDisplay"
								:entity-id="entityId"
								:is-local-storage="true"
								@update-grid="calcGridData" />

							<th class="table-spacer" />

							<sortable-grid-th
								cell-text="Modified"
								set-width="175px"
								sort-key="lastModifiedDate"
								:entity-id="entityId" 
								:is-local-storage="true"
								@update-grid="calcGridData" />

							<th width="30px">
								&nbsp;	<!-- action menu -->
							</th>
						</tr>
					</thead>
					<tbody>
						<paper-row
							v-for="paperData in filteredGridData"
							:class-id="paperData.classID"
							:paper-class-name="paperData.className"
							:paper-description="paperData.description"
							:paper-due-date="paperData.dueDateDisplay"
							:paper-encrypted-id="paperData.encryptedID"
							:paper-format-version-id="paperData.paperFormatVersionID"
							:paper-id="paperData.paperID"
							:paper-is-complete="paperData.isComplete"
							:paper-last-modified-date="paperData.lastModifiedDate"
							:paper-start-date="paperData.startDate"
							:paper-title="paperData.title"
							:paper-type="paperData.paperType"
							:key="paperData.paperID"
							@update-grid="calcGridData"
							context="PaperList"
							loader-id="PaperList" />
					</tbody>
				</table>
				<div v-else class="text-muted text-center mt-5" key="divNoPaperData">
					<p>No papers found.</p>
				</div>
			</div>
		</div>

		<route-modal />

	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import _includes from 'lodash/includes';
import _orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';
import AS_SyncData from '@/services/app/syncData';
import config from '@/config';
import GridSearch from '@/components/EntityGrid/Search';
import mixinResizer from '@/mixins/resizer';
import PaperRow from '@/components/EntityGrid/PaperRow';
import RouteModal from '@/components/modals/RouteModal';
import SortableGridTh from '@/components/SortableGridTh';

export default {
	name: 'PaperList',
	mixins: [mixinResizer],
	data() {
		return {
			filteredGridData: [],
			rzIgnoreBreakpoints: false,
			rzRef: 'divPaperListWrp',
		}
	},
	computed:{
		entityId(){
			return config.enums.Entity.PAPER;
		},
	},
	methods: {
		btnOpenCreateModalAnnotatedBibliography(){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'PaperListCreateAnnotatedBibliography',
				modalParams: {
					associatedClassId: '0',
				},
			});
		},
		btnOpenCreateModalDiscussionPost(){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'PaperListCreateDiscussionPost',
				modalParams: {
					associatedClassId: '0',
				},
			});
		},
		btnOpenCreateModalReferenceList(){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'PaperListCreateReferenceList',
				modalParams: {
					associatedClassId: '0',
				},
			});
		},
		btnOpenCreateModalResearchPaper(){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'PaperListCreateResearchPaper',
				modalParams: {
					associatedClassId: '0',
				},
			});
		},

		btnRebuildGrid(){
			// beta feature only for now
			this.$store.commit('loaders/ADD_ID', 'App');
			this.$store.commit('paperList/CLEAR_LIST');
			this.$store.commit('LAST_SYNC_DATE', '');

			// nexttick so the vuex store can get all the updates, before doing a full resync
			this.$nextTick(()=>{
				AS_SyncData({
					isStealthSync: true
				}).then(()=>{
					this.$store.commit('loaders/REMOVE_ID', 'App');
					
					this.emitter.emit('globalToasterOpen',{
						textContent: 'Papers rebuilt',
					});
				});
			});
		},//e:btnRebuildGrid

		calcGridData(){
			let searchedArray = [];

			let _data = _cloneDeep(this.$store.state.paperList.list);	// clone so i can add a sortBy property 

			// loop through each paper and prep for display
			_data.forEach((paperData) =>{
				// do not include Add-In papers, or any non valid formats
				if(!paperData.isOnlinePaper || !_includes(config.enums.Format, paperData.paperFormatVersionID)){
					return false;
				}

				if(_has(paperData, 'lastModifiedDate') && paperData.lastModifiedDate != '') {
					paperData.sortByLastModifiedDate = paperData.lastModifiedDate;	// remove hh:mm:ss so you can sort down to the day
				} else {
					paperData.sortByLastModifiedDate = '2000-01-01';
				}

				// due date isn't required - if you get back the default server value just blank it out on display
				if(!_has(paperData, 'dueDate') || paperData.dueDate === '2099-01-01T00:00:00') {
					paperData.dueDateDisplay = '';
				} else {
					paperData.dueDateDisplay = paperData.dueDate;
				}

				if(this.$store.state.gridSorts.searchTerm){
					// search by term
					if(paperData.title.toLowerCase().includes(this.$store.state.gridSorts.searchTerm)){
						searchedArray.push(paperData);
					}

				} else {
					// not searching
					searchedArray.push(paperData);

				}//e:_searchTerm
				
			});//e:forEach
			
			searchedArray = _uniqBy(searchedArray, 'paperID');

			switch(this.$store.state.gridSorts.paperListOrderBy){
				case 'dueDateDisplay':
					if(this.$store.state.gridSorts.paperListSorts['dueDateDisplay'] === 1){
						this.filteredGridData = _orderBy(searchedArray, ['dueDateDisplay', (item) => item.title.toLowerCase()], ['asc', 'asc']);
					} else {
						this.filteredGridData = _orderBy(searchedArray, ['dueDateDisplay', (item) => item.title.toLowerCase()], ['desc', 'asc']);
					}
					break;
				case 'lastModifiedDate':
					if(this.$store.state.gridSorts.paperListSorts['lastModifiedDate'] === 1){
						this.filteredGridData = _orderBy(searchedArray, ['sortByLastModifiedDate', (item) => item.title.toLowerCase()], ['asc', 'asc']);
					} else {
						this.filteredGridData = _orderBy(searchedArray, ['sortByLastModifiedDate', (item) => item.title.toLowerCase()], ['desc', 'asc']);
					}
					break;
				case 'title':
					if(this.$store.state.gridSorts.paperListSorts['title'] === 1){
						this.filteredGridData = _orderBy(searchedArray, [(item) => item.title.toLowerCase()], ['asc']);
					} else {
						this.filteredGridData = _orderBy(searchedArray, [(item) => item.title.toLowerCase()], ['desc']);
					}
					break;
			}//e:switch
			
			// console.log('checkClassAssociationInCreateDocumentModal 1');

			this.$nextTick(()=>{
				this.calcHeight();
				// console.log('checkClassAssociationInCreateDocumentModal 2');
				this.emitter.emit('checkClassAssociationInCreateDocumentModal');
				this.$store.commit('loaders/REMOVE_ID', 'App');
			});
		},//e:calcGridData
	},
	mounted(){
		this.$store.commit('loaders/ADD_ID', 'App');
		
		// console.log('checkClassAssociationInCreateDocumentModal 0');

		AS_SyncData({
			isStealthSync: true
		}).then(()=>{
			this.$store.commit('loaders/REMOVE_ID', 'App');
			
			this.calcGridData();
		});
	},
	components: {
		GridSearch,
		PaperRow,
		RouteModal,
		SortableGridTh,
	},
	destroyed(){
		this.$store.commit('loaders/REMOVE_ID', 'App');
	},
}
</script>

<style lang="scss" scoped>
	.paper-list-grid-wrp{
		align-items: center;
		display: grid;
		grid-auto-rows: min-content;
    	grid-template-columns: 1fr 1fr;
		row-gap: 16px;
	}
</style>
