import config from '@/config';

import PaperList from '@/views/PaperList';


import LegacyPaper from '@/views/LegacyPaper';
import LegacyPaperTabOverview from '@/views/LegacyPaper/TabOverview';
import LegacyPaperTabPreview from '@/views/LegacyPaper/TabPreview';


import ModalCreateAnnotatedBibliography from '@/views/PaperList/modals/CreateAnnotatedBibliography.vue';
import ModalCreateDiscussionPost from '@/views/PaperList/modals/CreateDiscussionPost.vue';
import ModalCreateReferenceList from '@/views/PaperList/modals/CreateReferenceList.vue';
import ModalCreateResearchPaper from '@/views/PaperList/modals/CreateResearchPaper.vue';

import PaperEdit from '@/views/PaperEdit';
import PaperTabExport from '@/views/Paper/TabExport';
import PaperTabOverview from '@/views/Paper/TabOverview';

// modules
import editorTab from "./paper/editor";
import outlineTab from "./paper/outline";
import researchTab from "./paper/research";

export default [
	{
		name: 'PaperList',
		component: PaperList,
		path: '/papers',
		meta: {
			layout: config.enums.Layout.PO,
			module: config.enums.Module.PAPER_LIST,
			tab: config.enums.Tab._
		},
		children: [
			{
				name: 'PaperListCreateAnnotatedBibliography',
				component: ModalCreateAnnotatedBibliography,
				path: 'create-annotated-bibliography/:associatedClassId',
				props: true,
				meta: {
					isPaperCreate: true,
					layout: config.enums.Layout.PO,
					module: config.enums.Module.PAPER_LIST,
					tab: config.enums.Tab._
				},
			},
			{
				name: 'PaperListCreateDiscussionPost',
				component: ModalCreateDiscussionPost,
				path: 'create-discussion-post/:associatedClassId',
				props: true,
				meta: {
					isPaperCreate: true,
					layout: config.enums.Layout.PO,
					module: config.enums.Module.PAPER_LIST,
					tab: config.enums.Tab._
				},
			},
			{
				name: 'PaperListCreateReferenceList',
				component: ModalCreateReferenceList,
				path: 'create-reference-list/:associatedClassId',
				props: true,
				meta: {
					isPaperCreate: true,
					layout: config.enums.Layout.PO,
					module: config.enums.Module.PAPER_LIST,
					tab: config.enums.Tab._
				},
			},
			{
				name: 'PaperListCreateResearchPaper',
				component: ModalCreateResearchPaper,
				path: 'create-research-paper/:associatedClassId',
				props: true,
				meta: {
					isPaperCreate: true,
					layout: config.enums.Layout.PO,
					module: config.enums.Module.PAPER_LIST,
					tab: config.enums.Tab._
				},
			},

		]
	},
	{
		path: '/legacy-paper/:encryptedId',
		props: true,
		component: LegacyPaper,
		meta: {
			layout: config.enums.Layout.PO,
			module: config.enums.Module.PAPER_EDIT,
			tab: config.enums.Tab._
		},
		children: [
			// { path: '', redirect: '/legacy-paper' },
			{
				path: 'overview',
				component: LegacyPaperTabOverview,
				name: 'LegacyPaperTabOverview',
				meta: {
					layout: config.enums.Layout.PO,
					module: config.enums.Module.PAPER_EDIT,
					tab: config.enums.Tab.OVERVIEW
				},
			},
			{
				path: 'preview',
				component: LegacyPaperTabPreview,
				name: 'LegacyPaperTabPreview',
				meta: {
					layout: config.enums.Layout.PO,
					module: config.enums.Module.PAPER_EDIT,
					tab: config.enums.Tab.PREVIEW
				},
			},

		]
	},
	{
		path: '/paper/:encryptedId',
		props: true,
		component: PaperEdit,
		meta: {
			layout: config.enums.Layout.PO,
			module: config.enums.Module.PAPER_EDIT,
			tab: config.enums.Tab._
		},
		children: [
			{ path: '', redirect: '/paper/editor' },
			...editorTab,
			...outlineTab,
			{
				name: 'PaperTabExport',
				component: PaperTabExport,
				path: 'export',
				meta: {
					layout: config.enums.Layout.PO,
					module: config.enums.Module.PAPER_EDIT
				},
			},
			{
				name: 'PaperTabOverview',
				component: PaperTabOverview,
				path: 'overview',
				meta: {
					layout: config.enums.Layout.PO,
					module: config.enums.Module.PAPER_EDIT
				},
			},
			...researchTab,
		],
	},
]