var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "tabContentScroll",
      class: [
        "overflow-y-auto mb-3 tab-content-scroll",
        { "p-3": !_vm.isNoPadding },
      ],
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }