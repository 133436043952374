<!--
- Write/Edit Page for Genesis Apa7 Papers
-->

<template>
	<div class="paper-edit-wrp">
		<nav-tabs>
			<nav-tab
				:to="{
					name:'LegacyPaperTabOverview'
				}"
				:is-active="$router.currentRoute.meta.tab === $enums.Tab.OVERVIEW">
				Overview
			</nav-tab>
			
			<nav-tab
				:to="{
					name:'LegacyPaperTabPreview'
				}"
				:is-active="$router.currentRoute.meta.tab === $enums.Tab.PREVIEW">
				Paper Preview
			</nav-tab>

		</nav-tabs>
		
		<transition name="route-fade" mode="out-in" appear>
			<router-view />
		</transition>
		

	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
// import config from '@/config';
import NavTab from '@/components/NavTab';
import NavTabs from '@/components/NavTabs';

export default {
	name: 'PaperEdit',
	components:{
		NavTab,
		NavTabs,
	},
	destroyed(){
		this.$store.commit('legacyPaper/CLEAR_FILE_BLOB');
	},
}
</script>

<style lang="scss" scoped>
	.paper-edit-wrp{
		// temporary until i get a better App.vue layout
		margin-top: -12px;
		margin-left: -6px;	
	}
</style>

