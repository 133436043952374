<template>
	<nav-tab-content-grid>
		<div class="d-grid g-overview">
			<div class="d-grid g-paper-title">
				<h1 v-html="Title" />
				<p class="small mb-0 text-paper-type">
					{{ calcFormatAndDocumentOutput }}
				</p>
			</div><!--g-paper-title-->

			<div class="d-grid g-left">
				<paper-details />
			
				<writing-tips />
			</div><!--g-left-->

			<div class="d-grid g-right">
				<recent-paper-saves />
			</div><!--g-right-->

		</div><!--g-overview-->
		
	</nav-tab-content-grid>
</template>

<script>

import NavTabContentGrid from '@/components/NavTabContentGrid';
import PaperDetails from './TabOverview/Details';
import ParseDocFormatType from '@/helpers/parse-doc-format-type';

// import PaperPlan from './TabOverview/PaperPlan';
import RecentPaperSaves from './TabOverview/RecentPaperSaves';
import WritingTips from '@/components/WritingTips';

export default {
	name: 'TabOverview',
	data() {
		return {
			// showPlan: false, // Boolean to show paper plan (true) or writing tips (false) in paper overview tab
		}
	},
	computed:{
		calcFormatAndDocumentOutput(){
			return ParseDocFormatType({
				paperFormatVersionID: this.$store.state.paperEdit.meta.PaperFormatVersionID,
				paperTypeEnum: this.$store.state.paperEdit.meta.PaperType,
			});
		},
		Title(){
			return this.$store.state.paperEdit.meta.Title || '&nbsp;';
		},
		
	},
	mounted(){
		// refreshing page off the Editor Tab > force user back to it
		if(!this.$store.state.paperEdit.ck.isEditorLoadedOnce){
			this.$router.push({
				name:'EditorReferencesCitations'
			}).catch(()=>{});
		}
	},
	components:{
		NavTabContentGrid,
		PaperDetails,
		// PaperPlan,
		RecentPaperSaves,
		WritingTips,
	},
	
}

</script>

<style lang="scss" scoped>
	@import "../../assets/styles/_vars_perrla.scss";

	@include color-mode(dark) {
		.text-paper-type {
			color: $gray-300;
		}
	}
	.g-overview {
		align-items: start;
		align-self:stretch;
		grid-column-gap: 3rem;
		grid-template-columns: 0.5fr 0.5fr 1fr 1fr;
		grid-template-rows: min-content auto;
		row-gap: 2rem;
		.g-paper-title {
			grid-column: 1 / span 4;
			grid-row: 1;
		}
		.text-paper-type {
			color: gray-700;
		}

		.g-left {
			grid-column: 1 / span 2;
			grid-row: 2;
		}
		.g-right {
			grid-column: 3 / span 2;
			grid-row: 2;
		}

	}//.g-overview

</style>