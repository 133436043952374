var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      class: _vm.calcRowClasses,
      on: {
        mouseover: function ($event) {
          _vm.isHover = true
        },
        mouseleave: function ($event) {
          _vm.isHover = false
        },
      },
    },
    [
      _c("td", { staticClass: "td-entity-icon" }, [
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isSelected,
                expression: "isSelected",
              },
            ],
            attrs: { href: "#", title: "Archive Reference" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toggleSelectReference.apply(null, arguments)
              },
            },
          },
          [_c("span", { staticClass: "svg bi-check-lg" })]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isSelected,
                expression: "!isSelected",
              },
            ],
          },
          [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isHover,
                    expression: "isHover",
                  },
                ],
                staticClass: "svg",
                attrs: { href: "#", title: "Mark Unfinished" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleSelectReference.apply(null, arguments)
                  },
                },
              },
              [_c("span", { staticClass: "svg icons8-unchecked-checkbox" })]
            ),
            _vm._v(" "),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isHover,
                  expression: "!isHover",
                },
              ],
              class: _vm.calcRefTypeIconClasses,
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "td-entity-title td-entity-click" }, [
        _c("div", {
          staticClass: "hanging-indent word-break",
          attrs: { title: "Open Reference" },
          domProps: { innerHTML: _vm._s(_vm.outputReferenceValue) },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.gotoEditReference.apply(null, arguments)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "table-spacer" }),
      _vm._v(" "),
      _c("td", { attrs: { width: "160px" } }, [
        _vm._v("\n\t\t" + _vm._s(_vm.outputCreatedDate) + "\n\t"),
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "td-more-menu" }, [
        !_vm.referenceData.isArchived
          ? _c("div", { staticClass: "dropstart" }, [
              _c("a", {
                class: ["svg bi-three-dots-vertical float-end"],
                attrs: {
                  href: "#",
                  "data-bs-toggle": "dropdown",
                  "aria-expanded": "false",
                  "aria-label": "More Options",
                },
              }),
              _vm._v(" "),
              _c("ul", { staticClass: "dropdown-menu" }, [
                _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item hover hover-primary",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.copyOutputReferenceValue.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_vm._v("Copy Text")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item hover hover-danger",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.deleteReferenceShowConfirm.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_vm._v("Archive Reference")]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }