<!--
- Research Notes Module > Edit Citation Modal
-- can be called from ReferenceEdit or PaperEdit
-->

<template>
	<generic-modal-wrap modal-id="ResearchNotesModalsCitationEdit">
		<h2 class="nodal-title">
			Edit Citation
		</h2>

		<form>
			<citation-form
				:load-citation-data="findCitation"
				:paper-format-version-id="paperFormatVersionID"
				:reference-unique-id="$store.state.modals.passData.referenceUniqueId"
				@enableCitationFormButton="isDisabled = false"
				@disableCitationFormButton="isDisabled = true" />

			<div class="row">
				<div class="col-6">
					<div class="d-grid">
						<button
							@click.prevent="btnDeleteCitation"
							class="btn btn-outline-danger"
							type="button">Delete Citation</button>
					</div>
				</div>
				<div class="col-6">
					<div class="d-grid">
						<button type="submit" class="btn btn-success" @click.prevent="updateCitation(false)" :disabled="isDisabled">
							Update Citation
						</button>
					</div>
				</div>
			</div>

		</form>
	</generic-modal-wrap>

</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import CitationForm from '@/modules/CitationForm';
import config from '@/config';
import GenericModalWrap from '@/components/modals/GenericWrap.vue';

export default {
	name: 'ResearchNotesModalsCitationEdit',
	props:{

	},
	data() {
		return {
			isDisabled: true,
		}
	},
	computed:{
		findCitation(){
			if(this.parentResearchNote){
				// parse citation 
				let parsedCitation = '';
				try {
					parsedCitation = JSON.parse(this.parentResearchNote.citation);
				} catch {
					return null;
				}

				return parsedCitation;
			}

			return null;
		},//e:findCitation

		paperFormatVersionID(){
			if(this.$store.state.paperEdit.meta.PaperFormatVersionID === ''){
				return 0;
			}
			return this.$store.state.paperEdit.meta.PaperFormatVersionID;
		},

		parentResearchNote(){
			// find this Research Note by the Research Note UID in the Route
			let findResearchNote = this.$store.state.researchNotes.list.find((researchNoteData)=>{
				return researchNoteData.genesisResearchNoteUniqueId.toUpperCase() === this.$store.state.modals.passData.researchNoteUniqueId;
			});
			if(findResearchNote){
				return findResearchNote;
			}

			return null;
		},

	},
	methods: {
		btnDeleteCitation(){
			this.updateCitation(true);
		},//e:btnDeleteCitation

		// edit and delete are so simliar use the isDelete flag
		updateCitation(isDelete){
			// console.log('updateCitation');

			this.$store.commit('loaders/ADD_ID', 'App');

			let newCitationResult;
			
			if(isDelete){
				newCitationResult = '';
			} else {
				if(this.$route.meta.module === config.enums.Module.PAPER_EDIT){
					newCitationResult = _cloneDeep(this.$store.state.paperEdit.citations.tempCitationData);
				} else if(this.$route.meta.module === config.enums.Module.REFERENCE_EDIT){
					newCitationResult = _cloneDeep(this.$store.state.researchNotes.tempCitationData);
				}

				newCitationResult.citationData.citationUniqueID = this.$store.state.modals.passData.citationUniqueId;
				newCitationResult.citationData.referenceUniqueID = this.$store.state.modals.passData.referenceUniqueId.toUpperCase();
			}

			// if i just added a research note, it can't pick it up
			let sendData = {
				'GenesisReferenceID': this.parentResearchNote.genesisReferenceId,
				'GenesisResearchNoteID': this.parentResearchNote.genesisResearchNoteId,
				'Note': this.parentResearchNote.note,
				'Citation': (isDelete) ? '' : JSON.stringify(newCitationResult),
			}
			
			// console.log('sendData');
			// console.log(sendData);

			ApiS_MakeCall({
				body: sendData,
				method: 'PUT',
				responseType: 'json',
				url: 'v3/GenesisResearchNote',
			}).then((responseData)=>{
				// console.log('responseData');
				// console.log(responseData);

				this.$store.commit('researchNotes/UPDATE_CITATION_BY_UNIQUE_ID', {
					newCitationData: newCitationResult,
					researchNoteUniqueId: this.$store.state.modals.passData.researchNoteUniqueId,
				}); 

				this.$store.commit('modals/CLEAR_MODAL_DATA');
				this.$store.commit('modals/CLOSE', 'ResearchNotesModalsCitationEdit');

				// emit back up to the parent to remove
				this.$emit('update-grid');

				this.$store.commit('loaders/REMOVE_ID', 'App');

			});//e:ApiS_MakeCall

		},//e:updateCitation
	},
	components: {
		CitationForm,
		GenericModalWrap
	}
}
</script>
