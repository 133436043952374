<!--
- Settings > App Settings
-
-->

<template>
	<nav-tab-content>
		<div class="row">
			<div class="col-8">
				<h3>Theme</h3>

				<form-row-slot label="Choose a theme">
					<div class="row mb-3">
						<div class="col">
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									id="PO_Theme_Light"
									name="PO_Theme"
									type="radio"
									:value="$enums.Theme.REGULAR"
									v-model="themeId" /> 
								<label class="form-check-label" for="PO_Theme_Light">
									Light
								</label>
							</div>
							
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									id="PO_Theme_Dark"
									name="PO_Theme"
									type="radio"
									:value="$enums.Theme.DARK"
									v-model="themeId" /> 
								<label class="form-check-label" for="PO_Theme_Dark">
									Dark
								</label>
							</div>

							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									id="PO_Theme_Pink"
									name="PO_Theme"
									type="radio"
									:value="$enums.Theme.SERRENA"
									v-model="themeId" /> 
								<label class="form-check-label" for="PO_Theme_Pink">
									Serena (Pink)
								</label>
							</div>
						</div>
					</div>
				</form-row-slot>

				<h3>Calendar</h3>

				<form-row-slot label="Time Zone">
					<div class="row">
						<div class="col">
							<select class="form-select" v-model="timeZone" aria-label="Role">
								<option :value="1">
									Eastern (-5 GMT)
								</option>
								<option :value="2">
									Central (-6 GMT)
								</option>
								<option :value="3">
									Mountain Arizona (-7 GMT)
								</option>
								<option :value="4">
									Mountain (-7 GMT)
								</option>
								<option :value="5">
									Pacific (-8 GMT)
								</option>
								<option :value="6">
									Alaska (-9 GMT)
								</option>
								<option :value="7">
									Hawaii (-10 GMT)
								</option>
							</select>
						</div>
					</div>
				</form-row-slot>

				<form-row-slot label="Starting Hour">
					<div class="row">
						<div class="col">
							<select class="form-select" v-model="calendarStartingHour">
								<option :value="1">
									1am
								</option>
								<option :value="2">
									2am
								</option>
								<option :value="3">
									3am
								</option>
								<option :value="4">
									4am
								</option>
								<option :value="5">
									5am
								</option>
								<option :value="6">
									6am
								</option>
								<option :value="7">
									7am
								</option>
								<option :value="8">
									8am
								</option>
								<option :value="9">
									9am
								</option>
								<option :value="10">
									10am
								</option>
								<option :value="11">
									11am
								</option>
								<option :value="12">
									12pm
								</option>
							</select>
						</div>
					</div>
				</form-row-slot>

				<form-row-slot label="Start Week On">
					<div class="row">
						<div class="col">
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									id="PO_StartWeekOn_Sunday"
									name="PO_StartWeekOn"
									type="radio"
									:value="0"
									v-model="calendarStartingDayOfWeek" /> 
								<label class="form-check-label" for="PO_StartWeekOn_Sunday">
									Sunday
								</label>
							</div>
							
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									id="PO_StartWeekOn_Monday"
									name="PO_StartWeekOn"
									type="radio"
									:value="1"
									v-model="calendarStartingDayOfWeek" /> 
								<label class="form-check-label" for="PO_StartWeekOn_Monday">
									Monday
								</label>
							</div>
						</div>
					</div>
				</form-row-slot>
				
				<form-row-slot label="Default View">
					<div class="row">
						<div class="col">
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									id="PO_DefaultView_Month"
									name="PO_DefaultView"
									type="radio"
									:value="1"
									v-model="calendarViewId" /> 
								<label class="form-check-label" for="PO_DefaultView_Month">
									Month
								</label>
							</div>
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									id="PO_DefaultView_Week"
									name="PO_DefaultView"
									type="radio"
									:value="2"
									v-model="calendarViewId" /> 
								<label class="form-check-label" for="PO_DefaultView_Week">
									Week
								</label>
							</div>
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									id="PO_DefaultView_Day"
									name="PO_DefaultView"
									type="radio"
									:value="3"
									v-model="calendarViewId" /> 
								<label class="form-check-label" for="PO_DefaultView_Day">
									Day
								</label>
							</div>
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									id="PO_DefaultView_Agenda"
									name="PO_DefaultView"
									type="radio"
									:value="4"
									v-model="calendarViewId" /> 
								<label class="form-check-label" for="PO_DefaultView_Agenda">
									Agenda
								</label>
							</div>
						</div>
					</div>
				</form-row-slot>


				<h3>Papers</h3>
 
				<!--
				<form-row-slot label="Auto-Save Period">
					<div class="row">
						<div class="col">
							<select class="form-select" v-model="paperAutoSave">
								<option :value="0">
									Disable
								</option>
								<option :value="7">
									1 Minute
								</option>
								<option :value="8">
									3 Minutes
								</option>
								<option :value="1">
									5 Minutes
								</option>
								<option :value="2">
									10 Minutes
								</option>
								<option :value="3">
									15 Minutes
								</option>
								<option :value="4">
									20 Minutes
								</option>
								<option :value="5">
									25 Minutes
								</option>
								<option :value="6">
									30 Minutes
								</option>
							</select>
						</div>
					</div>
				</form-row-slot>
				-->

				<form-row-slot label="Trigger Citation Create with Parentheses">
					<div class="row">
						<div class="col">
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									id="PO_TriggerCitationCreate_ENABLE"
									name="PO_TriggerCitationCreate"
									type="radio"
									:value="true"
									v-model="citationAutoInsert" /> 
								<label class="form-check-label" for="PO_TriggerCitationCreate_ENABLE">
									Enable
								</label>
							</div>
							
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									id="PO_TriggerCitationCreate_DISABLE"
									name="PO_TriggerCitationCreate"
									type="radio"
									:value="false"
									v-model="citationAutoInsert" /> 
								<label class="form-check-label" for="PO_TriggerCitationCreate_DISABLE">
									Disable
								</label>
							</div>

							<p>Create citations in the Editor by typing an opening parenthesis.</p>
						</div>
					</div>
				</form-row-slot>

			
				<h3>References</h3>
				<button
					class="btn btn-outline-danger mb-2"
					type="button"
					@click.prevent="btnArchiveReferences">
					Archive All References
				</button>

				<p class="text-muted">
					This will archive every reference in your PERRLA Library. Your existing papers will not be affected. Archived references and their Research Notes are NOT available to use in future papers. We do not recommend doing this without serious consideration.
				</P>
				
			</div><!--col-->
		</div><!--row-->

		<modal-archive-all-references />
	</nav-tab-content>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_SetLayout from '@/services/app/setLayout';
import AS_SyncData from '@/services/app/syncData';
import FormRowSlot from '@/components/form/RowSlot';
import mixinId from '@/mixins/id';
import ModalArchiveAllReferences from '@/components/Modals/ArchiveAllReferences';
import NavTabContent from '@/components/NavTabContent';

export default {
	name: 'AppSettings',
	mixins: [mixinId],
	computed:{
		calendarStartingDayOfWeek:{
			get(){
				return this.$store.state.customer.calendarStartingDayOfWeek;
			},
			set(value){
				this.btnUpdatePerrlaSettings('calendarStartingDayOfWeek', value);
			},
		},
		calendarStartingHour:{
			get(){
				return this.$store.state.customer.calendarStartingHour;
			},
			set(value){
				this.btnUpdatePerrlaSettings('calendarStartingHour', value);
			},
		},
		calendarViewId:{
			get(){
				return this.$store.state.customer.calendarViewId;
			},
			set(value){
				this.btnUpdatePerrlaSettings('calendarViewId', value);
			},
		},
		citationAutoInsert:{
			get(){
				return this.$store.state.customer.citationAutoInsert;
			},
			set(value){
				this.btnUpdatePerrlaSettings('citationAutoInsert', value);
			},
		},
		// paperAutoSave:{
		// 	get(){
		// 		return this.$store.state.customer.paperAutoSave;
		// 	},
		// 	set(value){
		// 		this.btnUpdatePerrlaSettings('paperAutoSave', value);
		// 	},
		// },
		themeId:{
			get(){
				return this.$store.state.customer.themeId;
			},
			set(value){
				this.btnUpdatePerrlaSettings('themeId', value);
			},
		},
		timeZone:{
			get(){
				return this.$store.state.customer.timeZone;
			},
			set(value){
				this.btnUpdatePerrlaSettings('timeZone', value);
			},
		},
	},
	methods: {
		btnArchiveReferences(){
			this.$store.commit('modals/OPEN', 'ArchiveAllReferences');
		},//e:btnArchiveReferences

		btnUpdatePerrlaSettings(key, value){
			let sendBody = {
				'calendarStartingDayOfWeek': this.$store.state.customer.calendarStartingDayOfWeek,				
				'calendarStartingHour': this.$store.state.customer.calendarStartingHour,
				'calendarViewID': this.$store.state.customer.calendarViewId,
				'citationAutoInsert': this.$store.state.customer.citationAutoInsert,
				// 'paperAutoSave': this.$store.state.customer.paperAutoSave,
				'themeID': this.$store.state.customer.themeId,
				'timeZone': this.$store.state.customer.timeZone
			}

			// correct casing
			if(key === 'themeId'){
				sendBody['themeID'] = value;
			} else {
				sendBody[key] = value;
			}
			if(key === 'calendarViewId'){
				sendBody['calendarViewID'] = value;
			} else {
				sendBody[key] = value;
			}
			
			this.$store.commit('loaders/ADD_ID', 'App');

			ApiS_MakeCall({
				body: sendBody,
				method: 'PUT',
				responseType: 'text',
				url: 'v4/Customer/Settings/Online',
			}).then((responseData)=>{
				// console.log('responseData');
				// console.log(responseData);
				
				AS_SyncData().then(()=>{
					AS_SetLayout().then(()=>{
						this.emitter.emit('globalToasterOpen',{
							textContent: 'Settings successfully updated',
						});

						this.$store.commit('loaders/REMOVE_ID', 'App');
					});//e:AS_SetLayout
				});//e:AS_SyncData

			});//e:ApiS_MakeCall
		},//e:btnUpdatePerrlaSettings
	},
	mounted() {
		AS_SyncData().then(()=>{
			// console.log(this.$store.state.customer);
			this.$store.commit('loaders/REMOVE_ID', 'App');
		});	//e:AS_SyncData
	},
	beforeRouteLeave (to, from, next) {
		if(to.name !== 'AccountBilling' && to.name !== 'MyProfile'){
			// before you leave this route, make sure the sidebar turns back on
			this.$store.commit('app/SET_IS_SIDEBAR_SHOWN', true);
		}
	
		next();
	},
	components: {
		ModalArchiveAllReferences,
		NavTabContent,
		FormRowSlot,
	}
}
</script>
