<template>
	<div class="instance-references">
		<br />
		<h1 :class="['text-center', {'fw-bold': $store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.APA7, 'fw-normal': $store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.MLA9}]">
			{{ referenceTitleDisplay }}
		</h1>

		<reference-page-row
			v-for="referenceObject in referenceObjectsInPaper"
			:key="referenceObject.referenceUniqueID"
			:reference-object="referenceObject"
			:reference-unique-id="referenceObject.referenceUniqueID" />
    </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import config from '@/config';
import PS_SaveDocument from '@/services/paper/saveDocument';
import ReferencePageRow from './ReferencePageRow';

export default {
	name: 'PaperReferenceSectionDiscussionPost',
	data() {
		return {
			referenceObjectsInPaper: [],	// starts empty - fills in after paper loads
		}
	},
	computed:{
		referenceTitleDisplay(){
			if(this.$store.state.paperEdit.meta.DocumentType === config.enums.DocumentType.ANNOTATED_BIBLIOGRAPHY || this.$store.state.paperEdit.meta.DocumentType === config.enums.DocumentType.REFERENCE_LIST){
				// AB or RL - always return paper "title"
				return this.$store.state.paperEdit.meta.Title;

			} else {
				// RP or DP - check format
				if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
					return 'Bibliography';

				} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
					// check for single or multiple references
					if(this.referenceObjectsInPaper.length === 1){
						return 'Work Cited';
					} else {
						return 'Works Cited';
					}
				}
				// APA7 will fall through to below
			}
			return 'References';
		},
	},
	methods:{
		generateReferencePage($opts){
			const _$opts = $opts || {};
			const _isSaveAfter = _$opts.isSaveAfter || false;
			const _isStealthSave = _$opts.isStealthSave || false;
			const _saveMessage = _has(_$opts, 'saveMessage') ? _$opts.saveMessage : '';

			this.referenceObjectsInPaper = [];
			
			// let the reference page reset to empty - the next tick will put them all back in, which calls mounted() for each one
			this.$nextTick(()=>{
				this.$store.state.paperEdit.referencesInPaper.forEach((referenceObject)=>{
					this.referenceObjectsInPaper.push(referenceObject);
				});
				
				if(_isSaveAfter){
					PS_SaveDocument({
						isMetaDataChanges: true,
						isStealthSave: _isStealthSave,
						message: _saveMessage,
					});
				}
			});
		},
	},
	created() {
		this.emitter.on('addReferenceAnnotations', ()=>{
			this.$store.commit('paperEdit/annotations/IS_INCLUDE', true);
			
			this.emitter.emit('globalToasterOpen',{
				textContent: 'Annotations added',
			});

			this.$nextTick(()=>{
				this.generateReferencePage();
			});
		});//e:addReferenceAnnotations

		// should rebuild all reference page row items (doing it this way because ckEditor does weird stuff when Vue changes the DOM)
		this.emitter.on('generateReferencePage', ($opts)=>{
			this.generateReferencePage($opts);
			this.$store.commit('paperEdit/SET_IS_DOCUMENT_SAVED', true);
		});//e:generateReferencePage
	},
	destroyed(){
		this.emitter.off('addReferenceAnnotations');
		this.emitter.off('generateReferencePage');
	},
	components: {
		ReferencePageRow,
	}
}
</script>
