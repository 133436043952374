var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "px-3 py-2" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-danger float-start me-2",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.btnThrowNetworkError.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\t\tNetwork Error\n\t\t\t")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "float-start block-height" }, [
          _vm._v(
            "\n\t\t\t\tGeneric error when something isn't sent correctly to the API. This one simulates a DocumentSave with the body property malformed\n\t\t\t"
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-danger float-start me-2",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.btnThrowVueError.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\t\tVue Component Error\n\t\t\t")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "float-start block-height" }, [
          _vm._v(
            "\n\t\t\t\tShould be picked up by Vue.config.errorHandler\n\t\t\t"
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-danger float-start me-2",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.btnThrowToRollbar.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\t\tSend to Rollbar\n\t\t\t")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "float-start block-height" }, [
          _vm._v("\n\t\t\t\tapp.rollbar.com\n\t\t\t"),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-danger float-start me-2",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.btnTestRegexIgnoredMatch.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\t\tTest Regex Ignored Match\n\t\t\t")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "float-start block-height" }, [
          _vm._v(
            "\n\t\t\t\ttesting a new Globa ignore Message Setting and Regex\n\t\t\t"
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("h1", [_vm._v("Error Tests")]),
        _vm._v(" "),
        _c("hr"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }