var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "paper-edit-wrp" },
    [
      _c(
        "nav-tabs",
        [
          _c(
            "nav-tab",
            {
              attrs: {
                to: {
                  name: "LegacyPaperTabOverview",
                },
                "is-active":
                  _vm.$router.currentRoute.meta.tab === _vm.$enums.Tab.OVERVIEW,
              },
            },
            [_vm._v("\n\t\t\tOverview\n\t\t")]
          ),
          _vm._v(" "),
          _c(
            "nav-tab",
            {
              attrs: {
                to: {
                  name: "LegacyPaperTabPreview",
                },
                "is-active":
                  _vm.$router.currentRoute.meta.tab === _vm.$enums.Tab.PREVIEW,
              },
            },
            [_vm._v("\n\t\t\tPaper Preview\n\t\t")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "route-fade", mode: "out-in", appear: "" } },
        [_c("router-view")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }