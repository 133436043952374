var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "research-note-row clearfix p-2 mb-2",
        { "sortable-row-pre-delete": _vm.isPreDelete },
      ],
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.setToEditMode($event.target)
        },
        mouseover: function ($event) {
          _vm.isHover = true
        },
        mouseleave: function ($event) {
          _vm.isHover = false
        },
      },
    },
    [
      _vm.isPreDelete
        ? _c("div", { staticClass: "sortable-row-pre-delete-content" }, [
            _c("p", [
              _vm._v("\n\t\t\tThis research note has been deleted.\n\t\t\t"),
              _c(
                "a",
                {
                  staticClass: "float-end",
                  attrs: { href: "#", title: "Undo Delete" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.btnUndoDelete.apply(null, arguments)
                    },
                  },
                },
                [_c("span", { staticClass: "svg icons8-undo" })]
              ),
            ]),
          ])
        : _c("div", [
            !_vm.isEditNote
              ? _c(
                  "a",
                  {
                    class: [
                      "btn-delete-research-note float-end d-print-none",
                      { invisible: !_vm.isHover },
                    ],
                    attrs: { href: "#", title: "Delete Research Note" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.preDeleteResearchNote.apply(null, arguments)
                      },
                    },
                  },
                  [_c("div", { staticClass: "svg bi-x-lg" })]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEditNote,
                  expression: "!isEditNote",
                },
              ],
              staticClass: "research-note-text",
              attrs: { title: "Click to Edit Research Note" },
              domProps: { innerHTML: _vm._s(_vm.researchNoteText) },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.editResearchNote.apply(null, arguments)
                },
              },
            }),
            _vm._v(" "),
            !_vm.isEditNote && _vm.calcCitationOutput == ""
              ? _c(
                  "a",
                  {
                    key: "aAddCitationBeta",
                    class: [
                      "research-note-citation btn btn-hover btn-hover-success-underline d-print-none p-0 float-end",
                      { invisible: !_vm.isHover },
                    ],
                    attrs: { href: "#", title: "Add Citation" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.openAddEditCitation(false)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "svg bi-plus-lg" }),
                    _vm._v(" Add citation\n\t\t"),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isEditNote && _vm.calcCitationOutput != ""
              ? _c(
                  "a",
                  {
                    key: "aEditCitation",
                    class: [
                      "research-note-citation btn btn-hover btn-hover-success-underline p-0 float-end",
                    ],
                    attrs: { href: "#", title: "Edit Citation" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.openAddEditCitation(true)
                      },
                    },
                  },
                  [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.calcCitationOutput) },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isEditNote,
                  expression: "isEditNote",
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.editTextareaValue,
                  expression: "editTextareaValue",
                },
              ],
              ref: "editTextArea",
              staticClass: "form-control",
              attrs: { rows: "2" },
              domProps: { value: _vm.editTextareaValue },
              on: {
                blur: _vm.blurEditTextarea,
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.editTextareaValue = $event.target.value
                  },
                  function ($event) {
                    return _vm.typeInput($event.target.value)
                  },
                ],
                keypress: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    if (!$event.shiftKey) return null
                    $event.preventDefault()
                    return _vm.updateResearchNote.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    if (
                      $event.ctrlKey ||
                      $event.shiftKey ||
                      $event.altKey ||
                      $event.metaKey
                    )
                      return null
                    $event.preventDefault()
                    return _vm.updateResearchNote.apply(null, arguments)
                  },
                ],
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  )
                    return null
                  return _vm.blurEditTextarea.apply(null, arguments)
                },
              },
            }),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }