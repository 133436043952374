<!--
- Paper Editor > Ribbon
-->

<template>
	<div class="rb-wrp clearfix">
		
		<div class="rb float-start">
			<!-- 
			<a
				class="rb-btn"
				href="#"
				@click.prevent="btnDownloadForPerrla">
				<div class="rb-icon rb-icon-download-for-perrla" />
				Download<br/> 
				for PERRLA
			</a> -->

			<a
				class="rb-btn"
				href="#"
				@click.prevent="btnDownloadWordDoc">
				<div class="rb-icon rb-icon-download-word-doc" />
				Download<br/>
				Word Doc
			</a>

			<a
				class="rb-btn"
				href="#"
				@click.prevent="btnDownloadPdf">
				<div class="rb-icon rb-icon-download-pdf" />
				Download<br/>
				PDF
			</a>

			<a
				class="rb-btn"
				href="#"
				@click.prevent="btnPrint">
				<div class="rb-icon rb-icon-print" />
				<br/>
				Print
			</a>

		</div> <!--rb float-start-->

		
		
	</div>
</template>

<script>
import config from '@/config';
import PS_DownloadDocumentVersionV4 from '@/services/paper/downloadDocumentVersionV4';

export default {
	name: 'LegacyPaperRibbon',
	methods:{
		// btnDownloadForPerrla(){
		// 	console.log('btnDownloadForPerrla');
		// },//e:btnDownloadForPerrla

		btnDownloadPdf(){
			PS_DownloadDocumentVersionV4({
				contentType: config.enums.DownloadPaperContentType.PDF,
				isStealth: true,
				paperId: this.$store.state.legacyPaper.openObject.paperID,
				paperTitle: this.$store.state.legacyPaper.openObject.title,
				platform: 1,
			});
		},//e:btnDownloadPdf

		btnDownloadWordDoc(){
			PS_DownloadDocumentVersionV4({
				contentType: config.enums.DownloadPaperContentType.WORD_SIMPLE,
				isStealth: true,
				paperId: this.$store.state.legacyPaper.openObject.paperID,
				paperTitle: this.$store.state.legacyPaper.openObject.title,
				platform: 1,
			});
		},//e:btnDownloadWordDoc

		btnPrint(){
			PS_DownloadDocumentVersionV4({
				contentType: config.enums.DownloadPaperContentType.PDF,
				isStealth: true,
				isPrint: true,
				paperId: this.$store.state.legacyPaper.openObject.paperID,
				paperTitle: this.$store.state.legacyPaper.openObject.title,
				platform: 1, // just Online Papers here
			});
		},//e:btnPrint
		
	},
}
</script>

<style lang="scss" scoped>
	@import "./../../assets/styles/_vars_perrla.scss";

	@include color-mode(dark) {
		.rb-wrp {
			border-color: $secondary-500;
		}	
	}

	.rb-wrp {
		border-top: 1px solid;
		border: 1px solid $secondary-300;
		position: relative;
	}
</style>