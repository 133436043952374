<!--
- Navigation Tabs > Navigatin Tab Content
-- Single Content Block
-- mainly used to auto scroll within any tab by default
-->

<template>
	<div :class="['overflow-y-auto mb-3 tab-content-scroll', {'p-3': !isNoPadding}]" ref="tabContentScroll">
		<slot />
	</div>
</template>

<script>
import mixinResizer from '@/mixins/resizer';

export default {
	name: 'NavTabContent',
	mixins: [mixinResizer],
	props:{
		isNoPadding:{
			default: false,
			type: Boolean,
		}
	},
	data() {
		return {
			rzRef: 'tabContentScroll',
		}
	},
}
</script>

<style lang="scss" scoped>
	@media print {
		div.tab-content-scroll {
			height: auto !important;
			padding: 0 !important;
		}
	}
</style>
