var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav-tab-content",
    { attrs: { "is-no-padding": true } },
    [
      _c("legacy-paper-ribbon"),
      _vm._v(" "),
      _c("iframe", {
        ref: "iframePaperPreview",
        attrs: { src: _vm.paperLinkSrc, width: "100%" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }