<!--
- Picker Day
-- based on https://github.com/charliekassel/vuejs-datepicker
-- 2024.11.15 (admin version uses vue3)
-->

<template>
	<div class="vdp-datepicker__calendar" v-show="showCalendar" @mousedown.prevent ref="calendarWrp" :id="calendarId">
		<header class="d-flex">
			<div @click.prevent="monthPrevious()" class="prev">
				&lt;
			</div>
			<div @click.prevent="monthNext()" class="next">
				&gt;
			</div>
			<div class="month-name flex-grow-1">
				{{ displayMonthYear }}
			</div>
			
			<button type="button" class="btn btn-sm btn-link hover-underline today" @click.prevent="monthToday()">
				<span v-show="showToday">Today</span>
			</button>
		</header>

		<div>
			<span class="cell day-header" v-for="d in daysOfWeek" :key="d.timestamp">{{ d }}</span>
			<template v-if="blankDays > 0">
				<span
					class="cell day blank"
					v-for="d in blankDays" :key="d.timestamp" />
			</template><!--
			--><span
					class="cell day"
					v-for="day in days" :key="day.timestamp"
					:class="dayClasses(day)"
					@click.prevent="selectDate(day)">{{ day.date }}</span>
		</div>
	</div>
</template>

<script>
import config from '@/config';
import dateFormat from "dateformat";
import {isEqual} from 'date-fns';

export default {
	name: 'PickerDay',
	props:{
		calendarId:{
			default: '',
			type: String
		},
		hasSelected: {
			default: false,
			type: Boolean,
		},
		selectedDate: {
			type: Date,
		},
		showCalendar: {
			default: false,
			type: Boolean,
		},
	},
	data() {
		return {
			displayDate: null,		// the JS display of the calendar, loads and sets based on selected date but can change locally
		}
	},
	watch:{
		showCalendar(newValue){
			if(newValue){
				// console.log('show calendar');
				this.displayDate = this.selectedDate;

				// check if the positioning is off the window or now
				this.$nextTick(()=>{
					if(this.$refs.calendarWrp){
						let rect = this.$refs.calendarWrp.getBoundingClientRect();
						if((rect.bottom + 40) > window.innerHeight){	// 40 becasue that's the "wiggle" room i have positioning this above or below
							this.$refs.calendarWrp.classList.add('position-above');
							this.$refs.calendarWrp.classList.remove('position-below');
						} else {
							this.$refs.calendarWrp.classList.add('position-below')
							this.$refs.calendarWrp.classList.remove('position-above');
						}
					}
				});
			} else {
				// console.log('hide calendar');
				if(this.$refs.calendarWrp){
					this.$refs.calendarWrp.classList.remove('position-above');
					this.$refs.calendarWrp.classList.remove('position-below');
				}
			}
		},
	},
	computed:{
		blankDays() {
			if(this.displayDate){
				let dObj = new Date(this.displayDate.getFullYear(), this.displayDate.getMonth(), 1);
				return dObj.getDay();
			}
			return null;
		},

		displayMonthYear(){
			if(this.displayDate){
				return dateFormat(this.displayDate, 'mmm. yyyy');
			}
			return null;
		},

		days() {
			if(!this.displayDate){
				this.displayDate = config.today;
				// return false;
			}

			let days = [];

			// set up a new date object to the beginning of the current 'page'
			let dObj = new Date(this.displayDate.getFullYear(), this.displayDate.getMonth(), 1);
			let daysInMonth = /8|3|5|10/.test(this.displayDate.getMonth()) ? 30 : this.displayDate.getMonth() === 1 ? (!(this.displayDate.getFullYear() % 4) && this.displayDate.getFullYear() % 100) || !(this.displayDate.getFullYear() % 400) ? 29 : 28 : 31
		
			for (let i = 0; i < daysInMonth; i++) {
				days.push({
					date: dObj.getDate(),
					isSelected: this.hasSelected && this.selectedDate && isEqual(this.selectedDate, dObj),
					isToday: isEqual(dObj, config.today),
					isWeekend: dObj.getDay(dObj) === 0 || dObj.getDay(dObj) === 6,
					timestamp: dObj.getTime(),
				});
				dObj.setDate(dObj.getDate() + 1);
			}
			return days;
		},//e:days
			
		daysOfWeek()  {
			return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
		},//e:daysOfWeek

		// On calendar picker, disable "Today" if the current day is already in view.
		showToday(){
			if(this.displayDate){
				
				if(this.displayDate.getYear() === config.today.getYear() && this.displayDate.getMonth() === config.today.getMonth()){
					return false;
				} else {
					return true;
				}
			}
			return false;
		},
	},

	methods: {
		dayClasses(day) {
			return {
				'highlighted': day.isHighlighted,
				'selected': day.isSelected,
				'today': day.isToday,
				'weekend': day.isWeekend,
			}
		},//e:dayClasses
		
		monthNext(){
			this.displayDate = new Date(this.displayDate.getFullYear(), this.displayDate.getMonth() + 1, this.displayDate.getDate());
		},

		monthPrevious(){
			this.displayDate = new Date(this.displayDate.getFullYear(), this.displayDate.getMonth() - 1, this.displayDate.getDate());
		},//monthPrevious

		monthToday(){
			let d = new Date();
			this.displayDate = new Date(d.getFullYear(), d.getMonth(), 1);
		},//e:monthToday

		selectDate(date){
			this.$emit('selectDate', date);
		},//e:selectDate
	},
}
</script>


<style lang="scss" scoped>
// copied from https://github.com/charliekassel/vuejs-datepicker
@import "../../../assets/styles/vars_perrla";

$selected-bg:		$success !default;
$selected-color:	#fff !default;

$today-bg:			$secondary-100 !default;
$today-color:		$body-color !default;

$weekend-bg:		$gray-200 !default;
$weekend-color:		$body-color !default;

$calendar-width: 400px;

.vdp-datepicker {
	position: relative;	
	text-align: left;
}

.vdp-datepicker__calendar {
	background: #fff;
	border: 1px solid $input-border-color;
	box-shadow: 0 0px 12px -2px rgba(9,45,66,0.33);//rgb(206, 212, 218)
	max-width: $calendar-width;
	position: absolute;	// removing this lets the calendar open incontext
	right: 0;
	z-index: 15000;
	&.position-above{
		bottom: 40px;
	}
	&.position-below{
		top: 40px;
	}
}


.vdp-datepicker__calendar header {
	display: block;
	line-height: 40px;

	.month-name {
		color: $body-color;
		text-align: center;
		font-weight: bold;
	}

	.prev, .next {
		position: relative;
		text-indent: -10000px;
		width: calc(100% / 7);

		&:after,&:after {
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
			border: 6px solid transparent;
		}
	}
	.prev:after {
		border-right: 10px solid $body-color;
		margin-left: -5px;
	}
	.next:after {
		border-left: 10px solid $body-color;
		margin-left: 5px;
	}

	.today{
		width: calc(100% / 3.5);	// make it twice the width of a calendar day so
	}
}//.vdp-datepicker__calendar header

 
.vdp-datepicker__calendar .cell {
	border: 1px solid transparent;
	color: $body-color;
	display: inline-block;
	height: 40px;
	line-height: 40px;
	padding: 0 5px;
	text-align: center;
	vertical-align: middle;
	width: calc(100% / 7);

	&.day-header {
		font-size: 75%;
		white-space: nowrap;
		cursor: inherit;
	}

	&:not(.blank):not(.disabled).day,
	&:not(.blank):not(.disabled).month,
	&:not(.blank):not(.disabled).year {
		cursor: pointer;
	}

	&:not(.blank):not(.disabled).day:hover,
	&:not(.blank):not(.disabled).month:hover,
	&:not(.blank):not(.disabled).year:hover {
		border: 1px solid $primary;
		border-radius: 0.25rem;
	}

	&.weekend {
		background: $calendar-weekend-bg;
		color: $calendar-weekend-color;
	}

	&.today {
		background: $calendar-today-bg;
		border-radius: 0.25rem;
		color: $calendar-today-color;
	}
	&.selected {
		background: $calendar-selected-bg;
		border-radius: 0.25rem;
		color: $calendar-selected-color;
	}

}//.vdp-datepicker__calendar .cell


</style>
