<!--
- Overview Tab > Writing Tips
-
-->

<template>
	<div class="d-grid g-writing-tips">
			
		<h2>Writing Tips</h2>
		
		<div ref="divWritingTipsWrp" class="overflow-y-scroll">
			<writing-tip-row
				v-for="(tipData, index) in showWritingTips"
				:key="index"
				:tip-data="tipData" />
		</div>
	</div><!--g-writing-tips-->
</template>

<script>
import _shuffle from 'lodash/shuffle';
import _sortBy from 'lodash/sortBy';
import mixinResizer from '@/mixins/resizer';
import WritingTipsData from './WritingTips/Data';
import WritingTipRow from './WritingTips/Row';

export default {
	name: 'WritingTips',
	mixins: [mixinResizer],
	computed:{
		showWritingTips(){
			// shuffles the tips / grabs the first 3 / sorts those by title
			return _sortBy(_shuffle(WritingTipsData).slice(0, 2), 'title');
		},
	},
	data(){
		return {
			rzRef: 'divWritingTipsWrp',
		}
	},
	methods: {
		// showPaperPlan(){
		// 	this.$parent.showPlan = true;
		// },
	},
	components: {
		WritingTipRow
	}
}
</script>

<style lang="scss" scoped>
	.g-writing-tips {
		grid-column: 1 / span 2;
		grid-row: 3;
	}//.g-writing-tips
</style>