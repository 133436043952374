<!--
- Select
-- renders a full row or splits it between the label and form element
-->
<template>
	<div>
		<!-- Split Row (ex: My Profile > Something)-->
		<div v-if="isSplit" class="form-display row mb-3" key="divSplitRow">
			<div class="col-label">
				<label
					:class="['form-label form-label-v2', {'fst-italic':isInvalidShown}]"
					:for="safeId"
					v-html="calcLabelOutput" />
			</div>

			<div class="col-value">
				<select :class="['form-select',  {'is-invalid':isInvalidShown}]" :id="safeId" :value="value" @change="selectChange($event.target.value)">
					<option v-for="(singleObject, key) in calcSelectObject" :key="key" :value="singleObject.value" :disabled="singleObject.isDisabled">
						{{ singleObject.name }}
					</option>
				</select>

				<div class="invalid-feedback" v-if="isInvalidShown">
					{{ invalidErrorMessage }}
				</div>
				
			</div>
		</div><!--divSplitRow-->

		<!-- Full Row (ex: Create Research Paper > Paper Format)-->
		<div class="form-group" v-else key="divFullRow">
			<label
				v-if="label"
				:class="[{'fst-italic':isInvalidShown}]"
				:for="safeId"
				v-html="calcLabelOutput" />

			<select :class="['form-select',  {'is-invalid':isInvalidShown}]" :id="safeId" :value="value" @change="selectChange($event.target.value)">
				<option v-for="(singleObject, key) in calcSelectObject" :key="key" :value="singleObject.value" :disabled="singleObject.isDisabled">
					{{ singleObject.name }}
				</option>
			</select>

			<div class="invalid-feedback" v-if="isInvalidShown">
				{{ invalidErrorMessage }}
			</div>

		</div><!--divFullRow-->
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import mixinId from '@/mixins/id';

export default {
	name: 'FormSelect',
	mixins: [mixinId],
	props:{
		formId: {
			type: String,
		},
		isClassSelect: {
			default: false,
			type: Boolean
		},
		isSplit:{	
			default: false, // if true then it will split the label and form control into two columns (ex: My Profile)
			type: Boolean,
		},
		label:{
			type: String,
		},
		selectObject:{
			type: Array
		},
		validateEnum: {
			default: 0,
			type: Number
		},
		value:{
			default: 0,
			type: [Number, String],
		},
	},
	data(){
		return {
			isInvalid: false,
		}
	},
	computed:{
		calcLabelOutput(){
			if(
				this.validateEnum === config.enums.Validation.EMAIL_REQUIRED || 
				this.validateEnum === config.enums.Validation.NUMERIC_REQUIRED ||
				this.validateEnum === config.enums.Validation.REQUIRED
			){
				return this.label + '*';
			}

			return this.label;
		},

		calcSelectObject(){
			if(this.isClassSelect){
				// use the classList to build an object in the proper layout
				let returnArray = [{
					value: "0",
					name: "-- NONE --",
				}];

				let _data = _cloneDeep(this.$store.state.classes.list);
				_data = _orderBy(_data, (item) => item.name.toLowerCase(), 'asc');

				_data.forEach((classData) =>{
					if(!classData.isArchived){
						returnArray.push({
							value: classData.classID,
							name: classData.name,
						});
					}
				});//e:forEach

				return returnArray;
			}
			
			return this.selectObject;
		},
		invalidErrorMessage(){
			if(this.thisValidation){
				return this.thisValidation.invalidErrorMessage;
			}
			return '';
		},
		isInvalidShown(){
			if(this.thisValidation){
				return this.thisValidation.isShown;
			}
			return false;
		},
		thisValidation(){
			if(this.validateEnum !== 0 && !_isEmpty(this.$store.state.forms.validation)){
				// find this validation by form id
				let findValidation = this.$store.state.forms.validation.find((validationData)=>{
					return validationData.fullId === this.safeId
				});
				if(findValidation){
					return findValidation;
				} else {
					return null;
				}
			}
			return null;
		},
	},
	methods:{
		selectChange(value){
			// console.log('selectChange');

			// treat this like a form input blur, on change make sure it's still valid
			AS_ValidateForm({
				singleFieldId: this.safeId
			}).then(()=>{
				this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
					formId: this.formId,
				});
				this.$emit('input', value);
			}).catch(()=>{});//e:AS_ValidateForm

		}//e:selectChange
	},
	mounted(){
		// auto add this item to form validation object in the store
		if(this.validateEnum !== 0){
			this.$store.dispatch('forms/addValidation', {
				formId: this.formId,
				fieldLabel: this.label,
				fullId: this.safeId,
				validateEnum: this.validateEnum,
			})
		}

	},
}
</script>
