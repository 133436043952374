/*
- Logs
-- write logs to local storage
-- ex: this.$store.state.logs.x
-- with Dexie.js this is really trim now!
*/
import _forEach from 'lodash/forEach';
import { db } from '@/data/db';


export default {
	namespaced: true,
	
	// state: {
	// },
	
	// mutations: {
	// },

	actions: {
		async clearAll () {
			await db.logs.clear();
		},//e:clearAll

		// assemble an array of objects that live in the users local log file
		async getAll() {
			// console.log('logs/getAll');
			return await db.logs.toArray();
		},//e:getAll

		// verify the log that was just added - done right after a successful PS_SaveDocument call
		async verify({}, $opts) {
			// console.log('logs/verify');
			// console.log($opts);
			// await db.logs.update($opts.verifyLogId, {s3Link: $opts.s3Link});
			
			// remove the successful call, no need to keep it since it will be on the server / activity log
			await db.logs.delete($opts.verifyLogId);
		},//e:verify

		// write a log placeholder - done before right at the start of PS_SaveDocument
		async write({rootState}, $opts) {
			// console.log('logs/write');
			// console.log($opts);
			let newId = await db.logs.add({
				date: new Date().toISOString(),
				msg: $opts.message,
				paperId: rootState.paperEdit.openObject.paperID,
				s3Link: $opts.s3Link,
			});

			return newId;
		},//e:write
	},
}