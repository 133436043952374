<!--
- Textarea
-- this isn't used anywhere that needs validation so remove that from this file 
-->
<template>
	<div class="form-input-v2 row mb-3">
		<div class="col-label">
			<label
				class="form-label form-label-v2"
				:for="safeId"
				v-html="calcLabelOutput" />
		</div><!--col-label-->

		<div class="col-value">
			<textarea
				:class="['form-control']"
				ref="focusElement"
				:id="safeId"
				:pendo-grab-text="pendoGrabText"
				:placeholder="placeholder"
				:rows="calcRows"
				:value="value"
				@input="typeInput($event.target.value)" />
		</div>
	</div><!--form-input-v2-->

</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import config from '@/config';
import mixinExpandTextareaById from '@/mixins/expand-textarea-by-id';
import mixinId from '@/mixins/id';

export default {
	name: 'FormTextareaV2',
	mixins: [mixinExpandTextareaById, mixinId],
	props:{
		formId: {
			type: String,
		},
		helpText: {
			type: String,
		},
		isAutosize:{
			default: false,
			type: Boolean
		},
		isHorizontal:{	// contact support is a horizontal form but the default wil be false for all other form inputs
			default: false,
			type: Boolean,
		},
		isSuggestBracketHelp: {
			default: false,
			type: Boolean
		},
		isSuggestTitleCase: {
			default: false,
			type: Boolean
		},
		label: {
			type: String,
		},
		pendoGrabText:{
			default: '',
			type: String,
		},
		placeholder:{
			default: '',
			type: String
		},
		role: {
			type: String,
		},
		setMinRows:{
			type: String,
		},
		value: {
			type: String,
		},
	},
	computed:{
		calcLabelOutput(){
			if(
				this.validateEnum === config.enums.Validation.EMAIL_REQUIRED || 
				this.validateEnum === config.enums.Validation.NUMERIC_REQUIRED ||
				this.validateEnum === config.enums.Validation.REQUIRED
			){
				return this.label + '*';
			}
			// if(this.thisValidation === )
			return this.label;
		},

		calcRows(){
			if(this.setMinRows){
				return this.setMinRows;
			} else {
				if(this.isAutosize){
					return "1";
				} else {
					return "3";
				}
			}
		},
	},
	methods:{
		typeInput(value){
			this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
				formId: this.formId,
			});

			if(this.isAutosize){
				this.expandTextarea('focusElement');
			}

			this.$emit('input', value);
		}//e:typeInput
	},
	created(){
		// burried under a next tick so the mixin refernece type can fully mount
		this.emitter.on('expandTextareaBus', ()=>{
			this.$nextTick(()=>{
				if(this.isAutosize){
					this.expandTextarea('focusElement');
				}
			});
		});
	},
	mounted(){
		if(this.isAutosize){
			this.$nextTick(()=>{
				this.expandTextarea('focusElement');
			});
		}
	},
	destroyed(){
		this.emitter.off('expandTextareaBus');
	},
}
</script>
