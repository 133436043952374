<!--
- Error Tests
-- Developer Tools only
-->

<template>
	<div class="px-3 py-2">
		<div class="row">
			<div class="col">
				<h1>Error Tests</h1>
				<hr />
			</div><!--col-->
		</div><!--row-->

		<div class="row">
			<div class="col">
				<button
					@click.prevent="btnThrowNetworkError"
					class="btn btn-outline-danger float-start me-2"
					type="button">
					Network Error
				</button>

				<p class="float-start block-height">
					Generic error when something isn't sent correctly to the API. This one simulates a DocumentSave with the body property malformed
				</p>

			</div><!--col-->
		</div><!--row-->

		<hr />

		<div class="row">
			<div class="col">
				<button
					@click.prevent="btnThrowVueError"
					class="btn btn-outline-danger float-start me-2"
					type="button">
					Vue Component Error
				</button>

				<p class="float-start block-height">
					Should be picked up by Vue.config.errorHandler
				</p>

			</div><!--col-->
		</div><!--row-->

		<hr />

		<div class="row">
			<div class="col">
				<button
					@click.prevent="btnThrowToRollbar"
					class="btn btn-outline-danger float-start me-2"
					type="button">
					Send to Rollbar
				</button>

				<p class="float-start block-height">
					app.rollbar.com
				</p>

			</div><!--col-->
		</div><!--row-->

		<hr />

		<div class="row">
			<div class="col">
				<button
					@click.prevent="btnTestRegexIgnoredMatch"
					class="btn btn-outline-danger float-start me-2"
					type="button">
					Test Regex Ignored Match
				</button>

				<p class="float-start block-height">
					testing a new Globa ignore Message Setting and Regex
				</p>
			</div>
		</div>
	
	</div>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_SyncData from '@/services/app/syncData';
import config from '@/config';

export default {
	name: 'ErrorTests',
	methods: {
		btnTestRegexIgnoredMatch(){
			// this should "trickle up" to the main error handler which i will use to test blocking error cases
			let testObject = {
				'foo': 1,
			}
			let x = testObject.bar.trim();
			console.log(x);


			// https://docs.rollbar.com/docs/javascript#ignoring-specific-exception-messages
			// if(config.useErrorLogger){
			// 	this.$rollbar.error("Can't find variable WEBSPELLCHECKER");
			// } else {
			// 	console.log('Error Logging is not defined');
			// }
			
		},//e:btnTestRegexIgnoredMatch

		btnThrowNetworkError(){
			// simulate Document save with the body malformed
			ApiS_MakeCall({
				body: 'contentX=123',
				contentType: 'application/x-www-form-urlencoded',
				ignoreAuth: true,
				method: 'PUT',
				responseType: 'text',
				url: 'v3/Paper',
			}).catch((errorMessage)=>{
				// console.log('errorMessage');
				// console.log(errorMessage);
				if(errorMessage != ''){
					console.log(errorMessage);
				}
			});
		},

		btnThrowToRollbar(){
			if(config.useErrorLogger){
				this.$rollbar.error('Throw an Error to Rollbar.');
			} else {
				console.log('Error Logging is not defined');
			}
		},

		btnThrowVueError(){
			console.log('btnThrowVueError');
			return fakeVar;
		},
	},
	mounted() {
		AS_SyncData({
			isStealthSync: true
		});
	},
}
</script>

<style lang="scss" scoped>
	.block-height {
		line-height: 24px;
		padding-top: 7px;
		margin-bottom: 0;
	}
</style>