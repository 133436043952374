import Settings from '@/views/Settings';
import AccountBilling from '@/views/Settings/AccountBilling';
import AppSettings from '@/views/Settings/AppSettings';
import MyProfile from '@/views/Settings/MyProfile';
import YourPapers from '@/views/Settings/YourPapers';

export default [
	{
		component: Settings,
		name: 'Settings',
		path: '/settings',
		children: [
			{
				name: 'AccountBilling',
				component: AccountBilling,
				path: 'account-billing',
			},
			{
				name: 'MyProfile',
				component: MyProfile,
				path: 'my-profile',
			},
			{
				name: 'AppSettings',
				component: AppSettings,
				path: 'app-settings',
			},
			{
				name: 'YourPapers',
				component: YourPapers,
				path: 'your-papers',
			},			
		],
	},
]