var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-input-v2 row mb-3" }, [
    _c("div", { staticClass: "col-label" }, [
      _c("label", {
        staticClass: "form-label form-label-v2",
        attrs: { for: _vm.safeId },
        domProps: { innerHTML: _vm._s(_vm.calcLabelOutput) },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-value" }, [
      _c("textarea", {
        ref: "focusElement",
        class: ["form-control"],
        attrs: {
          id: _vm.safeId,
          "pendo-grab-text": _vm.pendoGrabText,
          placeholder: _vm.placeholder,
          rows: _vm.calcRows,
        },
        domProps: { value: _vm.value },
        on: {
          input: function ($event) {
            return _vm.typeInput($event.target.value)
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }