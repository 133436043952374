<!--
- DataDecoder
-- so far this only works for <citations> but include other types later
-->

<template>
	<div>
		<div class="row">
			<div class="col">
				<h1>Decode Data</h1>
				<hr />
			</div><!--col-->
		</div><!--row-->

		<div class="row">
			<div class="col">
				<div class="form-check">
					<input
						class="form-check-input"
						type="radio"
						id="Decoder_Radio_outline"
						value="outline"
						v-model="decodeType" /> 
					<label class="form-check-label" for="Decoder_Radio_outline">
						&lt;outline&gt;
					</label>
				</div>
				<div class="form-check">
					<input
						class="form-check-input"
						type="radio"
						id="Decoder_Radio_citations"
						value="citations"
						v-model="decodeType" /> 
					<label class="form-check-label" for="Decoder_Radio_citations">
						&lt;citations&gt;
					</label>
				</div>
			</div><!--col-->
		</div><!--row-->
			
		<div class="row">
			<div class="col">
				
				<div class="form-group">
					<label>
						Input:
					</label>
						
					<textarea
						class="form-control"
						ref="txtInput"
						rows="5" />
				</div>
				
				
				<button class="btn btn-outline-success w-100" type="button" @click.prevent="btnDecodeData">
					Decode Data
				</button>

				<div class="form-group" v-show="outputValue !== ''">
					<label>
						Output
					</label>
					<div class="d-block bg-light text-dark">

						<div class="p-3" ref="jsonOutput" />
					</div>
				</div>
			</div><!--col-->
		</div><!--row-->

		<!--
		<hr />
		
		<div class="row">
			<div class="col">
				<h1>Dexie.js tests</h1>
				<p>This uses a third party package to easier manage IndexedDB - <a href="https://dexie.org/docs/Tutorial/Vue" target="_blank">https://dexie.org/docs/Tutorial/Vue</a></p>
			</div>
		</div>
		<div class="row">
			<div class="col-6">
				<h3>Friends</h3>
				<ul>
					<li v-for="friend in friends" :key="friend.id">
					{{ friend.name }}, {{ friend.age }}
					</li>
				</ul>

				<fieldset>
					<legend>Add new friend</legend>
					<label>
						Name:<input v-model="newName" type="text" />
					</label>
					<br />
					<label>
						Age:<input v-model="newAge" type="text" />
					</label>
					<br />
					<button @click="addFriend">Add Friend</button>
					<p>- {{ status }}</p>
				</fieldset> 
			
				<button @click="deleteFriend">Delete Friend with ID 2</button><br />
				<button @click="deleteAllFriends">Delete All My Friends</button><br />
				<button @click="showFriendsAge19">Only See people aged 19</button>
			</div>

			<div class="col-6">
				<h3>Enemies</h3>
				<ul>
					<li v-for="enemy in enemies" :key="enemy.id">
					{{ enemy.name }}, {{ enemy.age }}
					</li>
				</ul>


				<fieldset>
					<legend>Add new enemy</legend>
					<label>
						Name:<input v-model="newName" type="text" />
					</label>
					<br />
					<label>
						Age:<input v-model="newAge" type="text" />
					</label>
					<br />
					<button @click="addEnemy">Add Enemy</button>
					<p>- {{ status }}</p>
				</fieldset> 
			</div>
		</div>
		-->
	</div>
</template>

<script>
// import { db } from '@/data/db';
import jsonview from '@pgrabovets/json-view';
import Swal from 'sweetalert2';

export default {
	name: 'DataDecoder',
	data() {
		return {
			decodeType: 'outline',	// citations || outline 
			outputValue: '',
			
			// enemies: [],
			// friends: [],

			// status: '',
      		// newName: '',
      		// newAge: '',

		}
	},
	computed:{

	},
	methods: {
		

		// async addEnemy() {
		// 	console.log('addEnemy');
		// 	// Add the new enemy!
		// 	const id = await db.enemies.add({
		// 		name: this.newName,
		// 		age: this.newAge,
		// 	});

		// 	this.status = `Enemy ${this.newName} successfully added. Got id ${id}`;

		// 	this.loadMyEnemies();

		// 	// Reset form:
		// 	this.newName = '';
		// 	this.newAge = this.defaultAge;

		// },//e:addEnemy

		// async addFriend() {
			
		// 	// Add the new friend!
		// 	const id = await db.friends.add({
		// 		name: this.newName,
		// 		age: this.newAge,
		// 	});

		// 	this.status = `Friend ${this.newName} successfully added. Got id ${id}`;

		// 	this.loadMyFriends();

		// 	// Reset form:
		// 	this.newName = '';
		// 	this.newAge = this.defaultAge;

		
    	// },//e:addFriend

		
		// async deleteAllFriends(){
		// 	console.log('deleteAllFriends');

		// 	// this.loadMyFriends().then(()=>{
		// 		// console.log(this.friends);
		// 		let bulkDeleteIds = [];

		// 		this.friends.forEach((friendData)=>{
		// 			bulkDeleteIds.push(friendData.id);
		// 		});
		// 		// console.log(bulkDeleteIds);
		// 		await db.friends.bulkDelete(bulkDeleteIds);
		// 	// });
			
		// 	// // await db.friends.delete()
			
		// 	this.loadMyFriends();
			

		// },//e:deleteAllFriends
		
		

		// async deleteFriend(){

		// 	await db.friends.delete(2);
		// 	this.loadMyFriends();
		
		// },//e:deleteFriend

		btnDecodeData(){
			let inputValue = this.$refs.txtInput.value.trim();

			this.outputValue = '';
			
			this.$nextTick(()=>{
				try{
					if(this.decodeType === 'citations'){
						this.outputValue = JSON.parse(decodeURIComponent(window.atob(inputValue)));
						
						// create json tree object
						const tree = jsonview.create(this.outputValue);
						jsonview.render(tree, this.$refs.jsonOutput);
						jsonview.expand(tree);

					} else if(this.decodeType === 'outline'){
						this.outputValue = decodeURIComponent(window.atob(inputValue));
						this.$refs.jsonOutput.innerHTML = this.outputValue;
					}
				} catch(error){
					console.log(error);

					Swal.fire({
						allowOutsideClick: false,
						buttonsStyling: false,
						title: '',
						text: error,
						icon: 'error',
						confirmButtonText: 'Got it - thanks!',
						showCloseButton: false,
						showCancelButton: false,
						showConfirmButton: true,
						customClass:{
							confirmButton: 'btn btn-success me-2',
						},
					});
				}

			});//nextTick
		},//e:btnDecodeData


		// async loadMyEnemies(){
		// 	console.log('loadMyEnemies');
		// 	this.enemies = await db.enemies
		// 		.toArray();
		// },

		// async loadMyFriends(){
		// 	console.log('loadMyFriends');
		// 	this.friends = await db.friends
		// 		.toArray();
		// },

		

		// async showFriendsAge19(){
		// 	this.friends = await db.friends
		// 		.where("age")
		// 		.equalsIgnoreCase("19")
		// 		.toArray();
		// },//e:showFriendsAge19
	},
	created() {
	},
	mounted() {
		// this.loadMyFriends().then(()=>{
		// 	console.log('mounted - loadMyFriends is done');
		// });

		// this.loadMyEnemies().then(()=>{
		// 	console.log('mounted - loadMyEnemies is done');
		// });
	},
	components: {
	},
}
</script>
