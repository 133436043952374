<!--
- Sidebar
- Expands on Hover
-->
<template>
	<div @mouseenter="isHover = true" @mouseleave="isHover = false" class="d-print-none">
		<nav class="left-menu left-menu-top" :class="{'left-menu-closed': $root.isNavSmall, 'left-menu-hover': isHover}">
			<div class="left-menu-inner">
				<ul id="navWrp">
					<li>
						<router-link :to="{name:'Dashboard'}" :class="[{'current': routeNameMatch === 'Dashboard'}]" tabindex="-1">
							<span class="svg icons8-home-page" />
							<span class="nav-title">Home</span>
						</router-link>
					</li>

					<li>
						<a href="/Calendar/Index" title="Calendar" tabindex="-1">
							<span class="svg icons8-calendar" />
							<span class="nav-title">Calendar</span>
						</a>
					</li>

					<li>
						<router-link :to="{name:'ClassList'}" :class="[{'current': routeNameMatch === 'ClassList'}]" tabindex="-1">
							<span class="svg icons8-class" />
							<span class="nav-title">Classes</span>
						</router-link>
					</li>

					<li>
						<router-link :to="{name:'PaperList'}" :class="{'current': routeNameMatch === 'PaperList'}" tabindex="-1">
							<span class="svg icons8-document" />
							<span class="nav-title">Papers</span>
						</router-link>
					</li>
					<li>
						<router-link :to="{name:'ReferenceList'}" :class="{'current': routeNameMatch === 'ReferenceList'}" tabindex="-1">
							<span class="svg icons8-books" />
							<span class="nav-title">References</span>
						</router-link>
					</li>
					<li>
						<a href="/Assignments/Index" title="Assignments" tabindex="-1">
							<span class="svg icons8-edit-property" />
							<span class="nav-title">Assignments</span>
						</a>
					</li>
					<li>
						<a href="/ToDos/Index" title="To-Dos" tabindex="-1">
							<span class="svg icons8-to-do" />
							<span class="nav-title">To-Dos</span>
						</a>
					</li>
					<li>
						<router-link :to="{name:'ReminderList'}" :class="[{'current': routeNameMatch === 'ReminderList'}]" tabindex="-1">
							<span class="svg icons8-notification" />
							<span class="nav-title">Reminders</span>
						</router-link>
					</li>

				</ul>
			</div><!--left-menu-inner-->

			<nav class="left-menu left-menu-bottom" :class="{'left-menu-closed': $root.isNavSmall, 'left-menu-hover': isHover}">
				<ul>
					<li>
						<a href="https://perrla.zendesk.com" title="Help Center" tabindex="-1">
							<span class="svg icons8-help" />
							<span class="nav-title">Help Center</span>
						</a>
					</li>
					<li>
						<router-link :to="{name:'ContactSupport'}" :class="{'current': routeNameMatch === 'ContactSupport'}" tabindex="-1">
							<span class="svg icons8-speech-bubble" />
							<span class="nav-title ">Contact Support</span>
						</router-link>						
					</li>
				</ul>
			</nav> <!-- left-menu -->
		</nav>
	</div>
</template>

<script>
export default {
	name: 'SidebarComponent',
	watch: {
		$route() {
			if(this.$route.matched.length > 0){
				this.routeNameMatch = this.$route.matched[0].name;
			} else {
				this.$router.push({
					name: 'Dashboard'
				}).catch(()=>{});
			}
		}
	},
	data() {
		return {
			isHover: false,	// Hover over left menu (show/hide only when mini nav is on)
			routeNameMatch: '',
		}
	},
	mounted(){
		// if the route gets altered i won't have a clean match
		if(this.$route.matched.length > 0){
			this.routeNameMatch = this.$route.matched[0].name;
		} else {
			this.$router.push({
				name: 'Dashboard'
			}).catch(()=>{});
		}
	},
}
</script>

<style lang="scss" scoped>
	@import "../../assets/styles/_vars_perrla.scss";

	$single-menu-item-height: 40px;

	@include color-mode(dark) {
		.left-menu {
			background: $gray-800;
			border-right-color: $extra-dark;

			.left-menu-bottom, .left-menu-inner {
				background: $gray-800;
			}

			ul{
				li {
					a{
						color: $dark-body-color;

						&:hover {
							background: $gray-700;
						}
					}
				}
			}
		}
	}

	body.theme-serrena {
		.left-menu {
			background: #463b6a;
			border-right-color: shade-color(#463b6a, $btn-active-bg-tint-amount);

			.left-menu-bottom, .left-menu-inner {
				background: #463b6a;
			}

			ul{
				li {
					a{
						color: $dark-body-color;
						&.current {
							background: #dd4eae !important;
							color: $white;
						}

						&:hover {
							// background: $gray-700;
							background: #dd4eae !important;
						}
					}
				}
			}
		}
	}

	.left-menu {
		background: $gray-200;
		border-right: 2px solid $body-color;

		.left-menu-bottom, .left-menu-inner {
			background: $gray-200;
		}

		& {
			position: fixed;
			left: 0;
			width: $sidebar-width-full;
			z-index: 100;
		}

		ul {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			padding: 0;
			margin: 0;
			overflow: hidden;
			font-weight: $font-weight-bold;

			li {
				display: block;
				float: left;
				width: 100%;

				a {
					display: block;
					color: $body-color;
					font-family: $font-family-base;
					font-size: $font-size-base;
					font-weight: $font-weight-normal;
					height: $single-menu-item-height;
					line-height: $single-menu-item-height;
					padding: 0 0 0 0.5rem;
					overflow: hidden;
					text-decoration: none;

					&:hover {
						background: $gray-400;
					}
					&.current {
						background: $brand-500 !important;
						color: $white;
					}

					span.svg {
						float: left;
						margin-right: 0.5rem;
						font-size: 22px;
					}
				}
			}
		}
	}

	// scale down shrink sidbar nav  
	@include media-breakpoint-down(lg) {
		.left-menu {
			transition: ease-in-out width 0.12s;
			width: $sidebar-width-closed;
		}

		.left-menu-hover {
			transition: ease-in-out width 0.12s;
			width: $sidebar-width-full;
		}
	}

	// force the sidebar to small mode
	.left-menu-closed {
		transition: ease-in-out width 0.12s;
		width: $sidebar-width-closed;
	}
	.left-menu-closed.left-menu-hover {
		transition: ease-in-out width 0.12s;
		width: $sidebar-width-full;
	}

	.left-menu-top {
		top: $po-navbar-height;
		bottom: $single-menu-item-height * 2;

		.left-menu-inner {
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}
	}

	.left-menu-bottom {
		bottom: 0;
		left: 0;
	}

</style>