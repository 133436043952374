/*
- Single Vuex Module for Previewing a legacy paper
-- ex: this.$store.state.legacyPaper.x
*/


export default {
	namespaced: true,
	state: {
		fileUrl: '',
		openObject:	{},		// the paper open that is being previewed
	},//e:state

	mutations: {
        CLEAR_FILE_BLOB(state) {
			state.fileUrl = '';
		},
		SET_FILE_URL(state, value) {
			state.fileUrl = value;
		},
		SET_OPEN_OBJECT(state, value){
			state.openObject = value;
		},
	},//e:mutations
}
