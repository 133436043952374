<!--
- Date Picker control using vuejs-datepicker
-- https://github.com/charliekassel/vuejs-datepicker
-- 2024.05.03 (admin version uses vue3)
-->

<template>
	<div class="form-group" :id="id">
		<div class="d-flex">
			<div class="w-100">
				<label>{{ label }}</label>
			</div>
			<div v-if="isRemoveEndDate" class="icon-row flex-shrink-1">
				<div class="d-flex flex-row-reverse">
					<a href="#" @click.prevent="$emit('end-date-remove')">
						<span class="svg svg-delete bi-x-lg" />
					</a>
				</div>
			</div>

		</div><!--d-flex-->
		
		<div class="help-text mb-1" v-if="helpText" v-html="helpText" />

		<div :class="['d-flex p-relative', {'datepicker-ref-wiz': isReferenceWizard }]">
			<div :class="['ps-0',{'me-1': iOS || !isDateDisplayed || !isClearable, 'form-control-fake w-100': !isReferenceWizard}]">

				<input
					autocomplete="off"
					maxlength="2"
					placeholder="mm"
					ref="month"
					type="text"
					:class="['form-control-stealth form-control-w2', {'form-control me-2': isReferenceWizard, 'is-invalid-no-icon': isMonthInvalid, 'form-control-primary': isAllCommonUseShow(false)}]"
					:id="calcIdMonth"
					v-model="m"
					@blur="dateBlur('m')"
					@input="dateInput('m')"
					@keypress.enter="dateBlur('m')" />

				<span v-if="!isReferenceWizard" :class="['form-control-fake-text', {'placeholder': !isDateDisplayed}]">/</span>

				<input 
					autocomplete="off"
					maxlength="2"
					ref="day"
					placeholder="dd"
					type="text"
					v-model="d"
					:class="['form-control-stealth form-control-w2', {'form-control me-2': isReferenceWizard, 'is-invalid-no-icon': isDayInvalid, 'form-control-primary': isAllCommonUseShow(true)}]"
					:id="calcIdDay"
					@blur="dateBlur('d')"
					@input="dateInput('d')"
					@keypress.enter="dateBlur('d')" />

				<span v-if="!isReferenceWizard" :class="['form-control-fake-text', {'placeholder': !isDateDisplayed}]">/</span>

				<input 
					autocomplete="off"
					maxlength="5"
					placeholder="yyyy"
					ref="year"
					type="text"
					v-model="y"
					:class="['form-control-stealth form-control-w4', {'form-control': isReferenceWizard, 'form-control-primary': isCommonUseShow}]"
					:id="calcIdYear"
					@blur="dateBlur('y')"
					@input="dateInput('y')" 
					@keypress.enter="dateBlur('y')" />

			</div><!--form-control-fake-->
		
			<button :class="['btn btn-primary', {'ms-2': isDateDisplayed}]" type="button" @click.prevent="toggleCalendar" data-bs-toggle="tooltip" data-bs-title="Toggle Calendar">
				<span :class="['svg', {'bi-calendar3':!showCalendar, 'bi-x-lg':showCalendar}]" />
			</button>
			
			<div class="flex-shrink-1" v-show="!iOS && isDateDisplayed && isClearable">
				<button type="button" class="btn btn-sm btn-link hover-underline" @click.prevent="clearDate">
					Clear
				</button>
			</div>

			<div class="flex-shrink-1" v-show="!iOS && !isDateDisplayed && isTodayButton">
				<button type="button" class="btn btn-link hover-underline btn-today" @click.prevent="btnSelectToday">
					Today
				</button>
			</div>

			<picker-day
				:calendar-id="calendarId"
				:has-selected="hasSelected"
				:selected-date="selectedDate"
				:show-calendar="showCalendar"
				@selectDate="selectDate" />

		</div> <!--d-flex-->

		<div :class="['invalid-feedback', {'d-block': isDateInvalid}]">
			Please enter a full date or clear the field to remove the due date
		</div>

		<div :class="['invalid-feedback', {'d-block':isError}]">
			The ending date cannot be before or the same as the starting date above.
		</div>

		<div :class="['invalid-feedback', {'d-block':isInvalidShown}]">
			{{ invalidErrorMessage }}
		</div>

		

		<div v-if="isAddEndDate" class="d-grid">
			<button class="btn btn-hover btn-hover-success" @click.prevent="$emit('end-date-add')" type="button">
				<span class="svg bi-plus-lg" /> Add ending date
			</button>
		</div>
	</div>
</template>

<script>
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config.js';
import dateIsEntryInvalid from '@/helpers/date-is-entry-invalid';
import dateFormat from "dateformat";
import PickerDay from '@/components/form/datepicker/PickerDay';
import { v4 as uuidv4 } from 'uuid';

export default {
	name: 'FormDatePicker',
	props:{
		formId: {
			type: String,
		},
		helpText: {
			type: String
		},
		id: { 
			type: String
		},
		isAddEndDate: {	// conferences can have a start and end date optionally adding end
			default: false,
			type: Boolean
		},
		isAllCommonUse: {	// used for APA7 ref types (blue outline) - month and month
			default: false,
			type: Boolean
		},
		isCommonUse: {	// used for APA7 ref types (blue outline) - only around year
			default: false,
			type: Boolean
		},
		isClearable:{
			default: true,
			type: Boolean
		},
		isError: {
			default: false,
			type: Boolean
		},
		
		isRemoveEndDate: {	// conferences can have a start and end date optionally adding end
			default: false,
			type: Boolean
		},
		isTodayButton:{	// retrevial dates need a TODAY option (takes the place of CLEAR)
			default: false,
			type: Boolean
		},
		label: {
			type: String
		},
		paperFormat: [String, Number],
		validateIsAllDate:{	// when creating work a due date isn't required but if you enter one item (MM) then you have to enter the whole date
			default: false,
			type: Boolean,
		},
		validateIsRequired:{	// when creating reminder the whole date is required
			default: false,
			type: Boolean,
		}
	},
	data() {
		return {
			m: '',
			mInt: 0,
			d: '',
			dInt: 0,
			y: '',
			yInt: 0,
			hasSelected: false,		// sets to true once a real selection is made
			isDateInvalid: false,	// so far only used when creating Work but i want to use it in other places when i can
			isDayInvalid: false,
			isMonthInvalid: false,
			selectedDate: null,	// Date() object when selected
			showCalendar: false,	//show or hide the pickable calendar

			calendarId: '',
		}
	},
	computed:{
		calcIdDay(){
			return this.id + '_Day';
		},
		calcIdMonth(){
			return this.id + '_Month';
		},
		calcIdYear(){
			return this.id + '_Year';
		},
		iOS(){
			return this.$store.state.iOS;
		},
		invalidErrorMessage(){
			if(this.thisValidation){
				return this.thisValidation.invalidErrorMessage;
			}
			return '';
		},
		isCommonUseShow(){
			// only show the common use outline if the field is blank
			if(this.y === '') {
				return this.isCommonUse;
			} else {
				return false;
			}			
		},
		isDateDisplayed(){
			if(this.d != '' || this.m != '' || this.y != '') {
				return true;
			}
			return false;
		},
		isInvalidShown(){
			if(this.thisValidation){
				return this.thisValidation.isShown;
			}
			return false;
		},
		isReferenceWizard(){
			// a date picker field in the reference wizard is separated into three separate boxes, and only the year can be common use
			if(this.paperFormat === 0){
				return true;
			} else {
				return false;
			}
		},
		thisValidation(){
			if(this.validateIsRequired){
				// find this validation by form id
				let findValidation = this.$store.state.forms.validation.find((validationData)=>{
					return validationData.fullId === this.id
				});
				if(findValidation){
					return findValidation;
				} else {
					return null;
				}
			}
			return null;
		},
	},
	watch:{
		d(newValue){
			this.dInt = parseInt(newValue) || 0;
		},
		m(newValue){
			this.mInt = parseInt(newValue) - 1 || 0;
		},
		y(newValue){
			this.yInt = parseInt(newValue) || 0;
		},
	},
	methods: {
		btnSelectToday(){
			// console.log('btnSelectToday');

			this.selectedDate = new Date();

			// update text fields
			this.m = dateFormat(this.selectedDate, "mm");
			this.d = dateFormat(this.selectedDate, 'dd');
			this.y = dateFormat(this.selectedDate, 'yyyy');
			
			// create a format specific date string
			switch(this.paperFormat){
				case config.enums.Format.APA6:
				case config.enums.Format.APA7:
				case config.enums.Format.Turabian9:
					// October 5, 2021
					this.$emit('date-set', dateFormat(this.selectedDate, 'mmmm d, yyyy'));
					break;
				case config.enums.Format.MLA8:
				case config.enums.Format.MLA9:
					// 5 October 2021
					this.$emit('date-set', dateFormat(this.selectedDate, 'd mmmm yyyy'));
					break;
				default:
					// probably MM/DD/YYYY (reference wizard questions)
					this.$emit('date-set', dateFormat(this.selectedDate, 'mm/dd/yyyy'));
					break;
			}

			this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
				formId: this.formId,
			});

			this.hasSelected = true;
			this.isDateInvalid = false;
			this.isDayInvalid = false;
			this.isMonthInvalid = false;
			this.showCalendar = false;
			
		},//e:btnSelectToday

		checkRequiredValidation(){
			this.$nextTick(()=>{
				if(this.validateIsRequired){
					AS_ValidateForm({
						singleFieldId: this.id
					}).catch(()=>{});//e:AS_ValidateForm
				}
			});//e:nextTick
		},//e:checkRequiredValidation

		clearDate(){
			this.m = '';
			this.d = '';
			this.y = '';
			this.hasSelected = false;
			this.isDateInvalid = false;
			this.isDayInvalid = false;
			this.isMonthInvalid = false;
			this.showCalendar = false;
			this.$emit('date-clear');

			this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
				formId: this.formId,
			});

			// if this is required let the user know, they just cleared it and it's not valid anymore
			this.checkRequiredValidation();

		},//e:clearDate

		dateBlur(type){
			const listMonthsFull = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

			// convert single bits to double - ex 1 = 01
			if(type == 'd' && this.d.length == 1) {
				this.d = '0' + this.d;
			} else if(type == 'm' && this.m.length == 1) {
				this.m = '0' + this.m;
			} else if(type == 'y') {
				if(this.y.length === 1){
					this.y = '200' + this.y;
				} else if(this.y.length === 2){
					this.y = '20' + this.y;
				}
			}

			if(
				this.validateIsAllDate &&
				type === 'y' &&
				this.y != '' &&
				(this.m == '' || this.d == '')
			){
				// blur year filed with a value in year but no other fields;
				this.isDateInvalid = true;
				
			} else {
				this.isDateInvalid = false;
				
				let dateString = '';
				
				switch(this.paperFormat){
					case config.enums.Format.APA6:
					case config.enums.Format.APA7:
					case config.enums.Format.Turabian9:
						// MMMM D, YYYY
						// https://trello.com/c/4JDneqEm/428-tweak-to-title-page-date-field
						// Technically a user could enter Month and Year Only
						if(this.m != ''){
							dateString += listMonthsFull[(parseInt(this.m) - 1)] + ' ';
						}
						if(this.d != ''){
							dateString += parseInt(this.d);
							if(this.y != ''){	
								dateString += ',';
							}
							dateString += ' ';
						}
						if(this.y != ''){
							dateString += this.y;
						}
						break;
					case config.enums.Format.MLA8:
					case config.enums.Format.MLA9:
						// D MMMM YYYY
						dateString = parseInt(this.d) + ' ' + listMonthsFull[(parseInt(this.m) - 1)] + ' ' + this.y;
						break;
					default:
						// probably MM/DD/YYYY (reference wizard questions)
						if(this.m){
							dateString += this.m + '/';
						}
						if(this.d){
							dateString += this.d + '/';
						} 
						if(this.y){
							dateString += this.y;
						}
						break;
				}
				
				// console.log('dateString');
				// console.log(dateString);

				// we are just bluring the field now, check if there is a valid Month and Day before trying to actually set the date and call the Engine

				let isMonthInvalid = true;
				if(this.m.length == 0){
					isMonthInvalid = false;
				} else {
					isMonthInvalid = dateIsEntryInvalid({
						type: 'm',
						value: this.m,
					});
				}

				let isDayInvalid = true;
				if(this.d.length == 0){
					isDayInvalid = false;
				} else {
					isDayInvalid = dateIsEntryInvalid({
						type: 'd',
						value: this.d,
					});
				}

				if(!isMonthInvalid && !isDayInvalid){
					this.$emit('date-set', dateString);
				}

				if(type === 'y'){
					this.checkRequiredValidation();
				}
			}
		},//e:dateBlur

		dateInput(type) {
			// type could be m, d, or y

			// only accept numbers
			this[type] = this[type].replace(/[^0-9]/g,'');

			// auto advanced to the next field if maximum is reached
			if(type == 'm' && this.m.length == 2) {
				// check validaion of month
				this.isMonthInvalid = dateIsEntryInvalid({
					type: 'm',
					value: this.m,
				});
				this.$refs.day.focus();

			} else if(type == 'd' && this.d.length == 2) {
				this.isDayInvalid = dateIsEntryInvalid({
					type: 'd',
					value: this.d,
				});
				this.$refs.year.focus();
			} 
			
			this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
				formId: this.formId,
			});
		},//e:dateInput

		isAllCommonUseShow(isDay){
			// only show the common use outline if the field is blank
			if((isDay && this.d === '') || (!isDay && this.m === '') ) {
				return this.isAllCommonUse;
			} else {
				return false;
			}	
		},//e:isAllCommonUseShow

		// $emits @click of day in picker
		selectDate(date){
			
			this.selectedDate = new Date(date.timestamp);

			// update text fields
			this.m = dateFormat(this.selectedDate, "mm");
			this.d = dateFormat(this.selectedDate, 'dd');
			this.y = dateFormat(this.selectedDate, 'yyyy');
			
			// create a format specific date string
			switch(this.paperFormat){
				case config.enums.Format.APA6:
				case config.enums.Format.APA7:
				case config.enums.Format.Turabian9:
					// October 5, 2021
					this.$emit('date-set', dateFormat(this.selectedDate, 'mmmm d, yyyy'));
					break;
				case config.enums.Format.MLA8:
				case config.enums.Format.MLA9:
					// 5 October 2021
					this.$emit('date-set', dateFormat(this.selectedDate, 'd mmmm yyyy'));
					break;
				default:
					// probably MM/DD/YYYY (reference wizard questions)
					this.$emit('date-set', dateFormat(this.selectedDate, 'mm/dd/yyyy'));
					break;
			}

			this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
				formId: this.formId,
			});

			this.hasSelected = true;
			this.isDateInvalid = false;
			this.isDayInvalid = false;
			this.isMonthInvalid = false;
			this.showCalendar = false;
			
			this.checkRequiredValidation();

		},//e:selectDate

		toggleCalendar(){
			this.showCalendar = !this.showCalendar;
			
			// on open - configure the selected date
			if(this.showCalendar){
				if(this.d == '' || this.m == '' || this.y == ''){	
					// use today
					this.selectedDate = new Date();
				} else {
					// build date object
					this.selectedDate = new Date(this.yInt, this.mInt, this.dInt);
					this.hasSelected = true;
				}
			}
		},//e:toggleCalendar
	},
	created(){
		this.emitter.on('setDatePickerDate', ()=>{
			// once the UI loads, i'll need to grab the values in the fields and set them here in this component
			let $elDay = document.getElementById(this.id + '_Day');
			if($elDay){
				this.d = $elDay.value;
			}
			
			let $elMonth = document.getElementById(this.id + '_Month');
			if($elMonth){
				this.m = $elMonth.value;
			}

			let $elYear = document.getElementById(this.id + '_Year');
			if($elYear){
				this.y = $elYear.value;
			}

			this.calendarId = uuidv4().toUpperCase();
		});//e:setDatePickerDate
	},
	mounted(){
		if(this.validateIsRequired){
			this.$store.dispatch('forms/addValidation', {
				formId: this.formId,
				fieldLabel: this.label,
				fullId: this.id,
				validateEnum: config.enums.Validation.DATE,
			});
		}
	},
	components: {
		PickerDay
	}
}
</script>

