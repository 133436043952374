var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav-tab-content",
    [
      _c(
        "div",
        { staticClass: "d-grid g-research-notes-header d-print-none" },
        [
          _c(
            "button",
            {
              staticClass:
                "btn btn-sm btn-hover btn-hover-success d-print-none",
              attrs: { disabled: _vm.searchTerm !== "", type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.btnAddResearchNote.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n\t\t\t+ Add research note\t\n\t\t")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-grid g-sort-and-search d-print-none" }, [
            _c("div", { staticClass: "dropdown" }, [
              _c(
                "a",
                {
                  staticClass: "hover-underline text-body",
                  attrs: {
                    href: "#",
                    "data-bs-toggle": "dropdown",
                    role: "button",
                    "aria-expanded": "false",
                    id: "dropdownMenuResearchNotes",
                  },
                },
                [
                  _c("span", { staticClass: "dropdown-toggle" }, [
                    _vm._v("Sorted by: "),
                    _c("span", { staticClass: "fw-bold" }, [
                      _vm._v(_vm._s(_vm.sortModeTextOutput)),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "ul",
                {
                  staticClass: "dropdown-menu dropdown-menu-end",
                  attrs: { "aria-labelledby": "dropdownMenuResearchNotes" },
                },
                [
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item hover hover-primary",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.setSortBy(
                              _vm.$enums.ResearchNotesSortBy
                                .CREATED_DATE_NEWEST_FIRST
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\tCreated Date (newest first)\n\t\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item hover hover-primary",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.setSortBy(
                              _vm.$enums.ResearchNotesSortBy
                                .CREATED_DATE_OLDEST_FIRST
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\tCreated Date (oldest first)\n\t\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item hover hover-primary",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.setSortBy(
                              _vm.$enums.ResearchNotesSortBy
                                .CITATION_FIRST_TO_LAST
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\tCitation (first to last)\n\t\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item hover hover-primary",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.setSortBy(
                              _vm.$enums.ResearchNotesSortBy
                                .CITATION_LAST_TO_FIRST
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\tCitation (last to first)\n\t\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex" }, [
              _c("div", { staticClass: "w-100" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchTerm,
                      expression: "searchTerm",
                    },
                    {
                      name: "debounce",
                      rawName: "v-debounce:500ms",
                      value: _vm.calcGridData,
                      expression: "calcGridData",
                      arg: "500ms",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { placeholder: "Search research notes", type: "text" },
                  domProps: { value: _vm.searchTerm },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.searchTerm = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.searchTerm && _vm.searchTerm.length > 0,
                      expression: "searchTerm && searchTerm.length > 0",
                    },
                  ],
                  staticClass: "flex-shrink-1",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-link hover-underline",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.clearSearch.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\t\tClear\n\t\t\t\t\t")]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            _c("transition", { attrs: { name: "route-fade" } }, [
              _vm.isAddingNew
                ? _c("div", [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newNoteTextareaValue,
                          expression: "newNoteTextareaValue",
                        },
                      ],
                      ref: "newTextArea",
                      staticClass: "form-control mb-3",
                      attrs: { rows: "2" },
                      domProps: { value: _vm.newNoteTextareaValue },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.newNoteTextareaValue = $event.target.value
                          },
                          function ($event) {
                            return _vm.typeInput($event.target.value)
                          },
                        ],
                        keypress: [
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            if (!$event.shiftKey) return null
                            $event.preventDefault()
                            return _vm.saveNewNote(true)
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            if (
                              $event.ctrlKey ||
                              $event.shiftKey ||
                              $event.altKey ||
                              $event.metaKey
                            )
                              return null
                            $event.preventDefault()
                            return _vm.saveNewNote(false)
                          },
                        ],
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "esc", 27, $event.key, [
                              "Esc",
                              "Escape",
                            ])
                          )
                            return null
                          return _vm.cancelNewNote.apply(null, arguments)
                        },
                      },
                    }),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.filteredGridData.length > 0
              ? _c(
                  "div",
                  { key: "divSearchResults" },
                  _vm._l(_vm.filteredGridData, function (researchNoteData) {
                    return _c("research-note-row", {
                      key: researchNoteData.genesisResearchNoteUniqueId,
                      attrs: {
                        "research-note-citation": researchNoteData.citation,
                        "research-note-citation-output":
                          researchNoteData.citationOutput,
                        "research-note-genesis-reference-id":
                          researchNoteData.genesisReferenceId,
                        "research-note-genesis-research-note-id":
                          researchNoteData.genesisResearchNoteId,
                        "research-note-genesis-research-note-unique-id":
                          researchNoteData.genesisResearchNoteUniqueId,
                        "research-note-reference-unique-id":
                          researchNoteData.referenceUniqueID,
                        "research-note-text": researchNoteData.note,
                      },
                      on: { "update-grid": _vm.calcGridData },
                    })
                  }),
                  1
                )
              : _vm.filteredGridData.length === 0 && _vm.searchTerm != ""
              ? _c("div", { key: "divNoSearchResults" }, [
                  _c(
                    "p",
                    { staticClass: "fw-light org-size pt-5 text-center" },
                    [
                      _vm._v(
                        "\n\t\t\t\t\tNo results - try searching for something else\n\t\t\t\t"
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("modal-research-note-citation-add"),
      _vm._v(" "),
      _c("modal-research-note-citation-edit", {
        on: { "update-grid": _vm.calcGridData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }