var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav-tab-content", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _vm.$store.state.customer.role === _vm.$enums.Status.FREE_TRIAL
          ? _c(
              "div",
              { key: "divFreeTrial", staticClass: "mb-4" },
              [
                _c("h3", [_vm._v("Your PERRLA Free Trial")]),
                _vm._v(" "),
                _c("form-input-v2-display", {
                  attrs: {
                    "display-class": "text-danger",
                    label: "Subscription",
                    value: _vm.freeTrialOutput,
                  },
                }),
                _vm._v(" "),
                _c("form-row-slot", [
                  _c("div", { staticClass: "d-grid" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.btnGoToSubscriptionSettings.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\tSubscribe to PERRLA now\n\t\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ]),
                ]),
              ],
              1
            )
          : _vm.isSuscriptionExpired
          ? _c(
              "div",
              { key: "divExpired", staticClass: "mb-4" },
              [
                _c("h3", [_vm._v("Subscription")]),
                _vm._v(" "),
                _c("form-input-v2-display", {
                  attrs: {
                    "display-class": "text-danger",
                    label: "Subscription",
                    value: _vm.expiredDateOutput,
                  },
                }),
                _vm._v(" "),
                _c("form-row-slot", [
                  _c("div", { staticClass: "d-grid" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.btnGoToSubscriptionSettings.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\tRenew your PERRLA Subscription\n\t\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("br"),
              ],
              1
            )
          : _vm.isCancelled
          ? _c(
              "div",
              { key: "divCancelled", staticClass: "mb-4" },
              [
                _c("h3", [_vm._v("Subscription")]),
                _vm._v(" "),
                _c("form-input-v2-display", {
                  attrs: {
                    "is-full-width": true,
                    "display-class": "text-danger",
                    label: "Subscription",
                    value:
                      "Your subscription was cancelled. You have full access to your PERRLA until your current subscription expires.",
                  },
                }),
                _vm._v(" "),
                _c("form-input-v2-display", {
                  attrs: {
                    label: "Expiration Date",
                    "is-full-width": true,
                    value: _vm.expiredDateOutput,
                  },
                }),
                _vm._v(" "),
                _c("form-row-slot", [
                  _c("div", { staticClass: "d-grid" }, [
                    _vm.$store.state.customer.isRecurring
                      ? _c(
                          "button",
                          {
                            key: "buttonRestore",
                            staticClass: "btn btn-outline-primary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.btnRestoreSubscription.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\tRestore My Subscription\n\t\t\t\t\t\t"
                            ),
                          ]
                        )
                      : _c(
                          "button",
                          {
                            key: "buttonRenew",
                            staticClass: "btn btn-outline-primary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.btnGoToSubscriptionSettings.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\tRenew your PERRLA Subscription\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                  ]),
                ]),
                _vm._v(" "),
                _c("br"),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isSuscriptionExpired
          ? _c(
              "form",
              [
                _c("h3", [_vm._v("Personal Information")]),
                _vm._v(" "),
                _c("form-input-v2", {
                  attrs: {
                    label: "First Name",
                    "form-id": _vm.zFormId,
                    "validate-enum": _vm.$enums.Validation.REQUIRED,
                  },
                  model: {
                    value: _vm.firstName,
                    callback: function ($$v) {
                      _vm.firstName = $$v
                    },
                    expression: "firstName",
                  },
                }),
                _vm._v(" "),
                _c("form-input-v2", {
                  attrs: {
                    label: "Last Name",
                    "form-id": _vm.zFormId,
                    "validate-enum": _vm.$enums.Validation.REQUIRED,
                  },
                  model: {
                    value: _vm.lastName,
                    callback: function ($$v) {
                      _vm.lastName = $$v
                    },
                    expression: "lastName",
                  },
                }),
                _vm._v(" "),
                _vm.$store.state.customer.organizationId === 0
                  ? _c(
                      "div",
                      { key: "divInputEmailSingle" },
                      [
                        _c("form-input-v2", {
                          attrs: {
                            label: "Email",
                            "form-id": _vm.zFormId,
                            "validate-enum":
                              _vm.$enums.Validation.EMAIL_REQUIRED,
                          },
                          model: {
                            value: _vm.email,
                            callback: function ($$v) {
                              _vm.email = $$v
                            },
                            expression: "email",
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { key: "divInputEmailAddMore" },
                      [
                        _c("form-emails-v2", {
                          attrs: {
                            label: "Email",
                            "form-id": _vm.zFormId,
                            "org-email": _vm.email,
                          },
                        }),
                      ],
                      1
                    ),
                _vm._v(" "),
                _c("form-row-slot", [
                  _c("div", { staticClass: "d-grid" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-outline-primary border-0 text-start",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.btnResetPassword.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\tReset my PERRLA Password\n\t\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("h3", [_vm._v("Institutional Information")]),
                _vm._v(" "),
                _c("form-input-v2", {
                  attrs: { label: "Institution Name", "form-id": _vm.zFormId },
                  model: {
                    value: _vm.institution,
                    callback: function ($$v) {
                      _vm.institution = $$v
                    },
                    expression: "institution",
                  },
                }),
                _vm._v(" "),
                _c("form-select", {
                  attrs: {
                    label: "Preferred Format",
                    "is-split": true,
                    "form-id": _vm.zFormId,
                    "select-object": _vm.preferredFormatList,
                  },
                  model: {
                    value: _vm.preferredFormatVersionID,
                    callback: function ($$v) {
                      _vm.preferredFormatVersionID = $$v
                    },
                    expression: "preferredFormatVersionID",
                  },
                }),
                _vm._v(" "),
                _c("form-row-slot", [
                  _c("div", { staticClass: "d-grid" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: {
                          disabled: !_vm.isSubmitClickable,
                          type: "submit",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.btnUpdateProfile.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\t\t\tUpdate Profile\n\t\t\t\t\t\t")]
                    ),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }