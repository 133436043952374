<!--
- Row for a single writing tip
-
-->

<template>
	<div class="row-article" @click.prevent="loadArticle">
		<h4>{{ tipData.title }}</h4>
		<p>{{ tipData.blurb }} <a href="#" title="Learn more">Learn More...</a></p>
	</div>
</template>

<script>

export default {
	name: 'WritingTipRow',
	props:{
		tipData:{
			required: true,
			type: Object
		}
	},
	methods: {
		loadArticle(){
			let win = window.open(this.tipData.link, '_blank');
			win.focus();
		},
	},
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/vars_perrla";

@include color-mode(dark) {
	.row-article {
		p{	
			color: $gray-300;
		}
	}
}

.row-article {
	border: 1px solid $gray-500;
	border-radius: $border-radius-lg;
    padding: 0.5rem 0.5rem 1rem;
	margin: 0 0 0.75rem 1rem;

    h4 {
		font-size: $font-size-base;
		font-weight: $font-weight-normal;
		margin-bottom: 0.5rem;
		
    }

    p {
        color: $gray-700;
		font-size: 0.8rem;
		line-height: 1.1rem;
		margin-bottom: 0.125rem;
    }
}

</style>
