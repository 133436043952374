var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col d-print-none" }, [
        _c(
          "h1",
          { staticClass: "float-start" },
          [
            _c(
              "router-link",
              {
                staticClass: "hover-underline",
                attrs: { to: { name: "ReferenceList" } },
              },
              [_vm._v("\n\t\t\t\t\tReferences\n\t\t\t\t")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "float-end" }, [
          _c("div", { staticClass: "dropdown float-end" }, [
            _c(
              "a",
              {
                staticClass: "btn-dropdown dropdown-toggle me-2",
                attrs: {
                  href: "#",
                  role: "button",
                  "data-bs-toggle": "dropdown",
                  "aria-expanded": "false",
                },
              },
              [
                _vm._v(
                  "\n\t\t\t\t\t\t" +
                    _vm._s(_vm.preferredFormatVersionIdOutput) +
                    "\n\t\t\t\t\t"
                ),
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticClass: "dropdown-menu dropdown-menu-end" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    class: [
                      "dropdown-item",
                      {
                        "dropdown-item-selected":
                          _vm.$store.state.references.displayFormatVersionId ===
                          _vm.$enums.Format.APA7,
                        "hover hover-primary":
                          _vm.$store.state.references.displayFormatVersionId !==
                          _vm.$enums.Format.APA7,
                      },
                    ],
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.setPreferredFormatVersionId(
                          _vm.$enums.Format.APA7
                        )
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\t\t\t\tAPA 7\n\t\t\t\t\t\t\t")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: [
                      "dropdown-item",
                      {
                        "dropdown-item-selected":
                          _vm.$store.state.references.displayFormatVersionId ===
                          _vm.$enums.Format.MLA9,
                        "hover hover-primary":
                          _vm.$store.state.references.displayFormatVersionId !==
                          _vm.$enums.Format.MLA9,
                      },
                    ],
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.setPreferredFormatVersionId(
                          _vm.$enums.Format.MLA9
                        )
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\t\t\t\tMLA 9\n\t\t\t\t\t\t\t")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: [
                      "dropdown-item",
                      {
                        "dropdown-item-selected":
                          _vm.$store.state.references.displayFormatVersionId ===
                          _vm.$enums.Format.Turabian9,
                        "hover hover-primary":
                          _vm.$store.state.references.displayFormatVersionId !==
                          _vm.$enums.Format.Turabian9,
                      },
                    ],
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.setPreferredFormatVersionId(
                          _vm.$enums.Format.Turabian9
                        )
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\t\t\t\tTurabian 9\n\t\t\t\t\t\t\t")]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "button",
            {
              staticClass:
                "btn btn-clipboard btn-sm btn-outline-primary d-print-none float-end",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.copyOutputReferenceValue.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n\t\t\t\tCopy Reference Text\n\t\t\t")]
          ),
          _vm._v(" "),
          _c("p", {
            directives: [
              { name: "add-blank-target", rawName: "v-add-blank-target" },
            ],
            staticClass: "reference-value-heading ms-3 me-3 mb-3 max-width-900",
            domProps: { innerHTML: _vm._s(_vm.outputReferenceValue) },
          }),
          _vm._v(" "),
          _c(
            "nav-tabs",
            [
              _c(
                "nav-tab",
                {
                  attrs: {
                    to: {
                      name: "ReferenceTabPapers",
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\tPapers\n\t\t\t\t")]
              ),
              _vm._v(" "),
              _c(
                "nav-tab",
                {
                  attrs: {
                    to: {
                      name: "ReferenceTabCitations",
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\tCitations\n\t\t\t\t")]
              ),
              _vm._v(" "),
              _c(
                "nav-tab",
                {
                  attrs: {
                    to: {
                      name: "ReferenceTabResearchNotes",
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\tResearch\n\t\t\t\t")]
              ),
              _vm._v(" "),
              _c(
                "nav-tab",
                {
                  attrs: {
                    to: {
                      name: "ReferenceTabDetails",
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\tDetails\n\t\t\t\t")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "route-fade", mode: "out-in", appear: "" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }