var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.closeAllMenus,
          expression: "closeAllMenus",
        },
      ],
      staticClass: "navbar navbar-expand filebar",
      class: [{ "filebar-hover": _vm.isActive }],
    },
    [
      _c("ul", { staticClass: "navbar-nav flex-fill" }, [
        _c(
          "li",
          {
            class: [
              "nav-item",
              {
                dropdown: !_vm.isReadOnly,
                "dropdown-hover": _vm.openMenuEnum === _vm.menuEnums.FILE,
              },
            ],
            on: {
              mouseenter: function ($event) {
                return _vm.mouseEnterMenu(_vm.menuEnums.FILE)
              },
            },
          },
          [
            _c(
              "a",
              {
                class: [{ show: _vm.openMenuEnum === _vm.menuEnums.FILE }],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleMenu(_vm.menuEnums.FILE)
                  },
                },
              },
              [_vm._v("File")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "dropdown-menu",
                  { show: _vm.openMenuEnum === _vm.menuEnums.FILE },
                ],
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("saveDocument")
                      },
                    },
                  },
                  [_vm._v("Save")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.printDocument.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Print")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: { to: { name: "PaperTabOverview" } },
                  },
                  [_vm._v("\n\t\t\t\t\tView recent saves\n\t\t\t\t")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCreateCopy.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Create a copy")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: [
                      "dropdown-item",
                      {
                        disabled:
                          _vm.documentType !==
                          _vm.$enums.DocumentType.DISCUSSION_POST,
                      },
                    ],
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.copyDiscussionPostToClipboard.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [_vm._v("Copy paper to clipboard")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddOpenDownloadedDocument(
                          _vm.$enums.DownloadPaperContentType.WORD_SIMPLE
                        )
                      },
                    },
                  },
                  [_vm._v("Download Simplified Word File (.docx)")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddOpenDownloadedDocument(
                          _vm.$enums.DownloadPaperContentType.WORD
                        )
                      },
                    },
                  },
                  [_vm._v("Download PERRLA Word File (.docx)")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddOpenDownloadedDocument(
                          _vm.$enums.DownloadPaperContentType.PDF
                        )
                      },
                    },
                  },
                  [_vm._v("Download as PDF (.pdf)")]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: [
              "nav-item",
              {
                dropdown: !_vm.isReadOnly,
                "dropdown-hover": _vm.openMenuEnum === _vm.menuEnums.EDIT,
              },
            ],
            on: {
              mouseenter: function ($event) {
                return _vm.mouseEnterMenu(_vm.menuEnums.EDIT)
              },
            },
          },
          [
            _c(
              "a",
              {
                class: [{ show: _vm.openMenuEnum === _vm.menuEnums.EDIT }],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleMenu(_vm.menuEnums.EDIT)
                  },
                },
              },
              [_vm._v("Edit")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "dropdown-menu",
                  { show: _vm.openMenuEnum === _vm.menuEnums.EDIT },
                ],
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("undo")
                      },
                    },
                  },
                  [_vm._v("Undo")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("redo")
                      },
                    },
                  },
                  [_vm._v("Redo")]
                ),
                _vm._v(" "),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.configUseWProofreader,
                      expression: "configUseWProofreader",
                    },
                  ],
                  staticClass: "dropdown-divider",
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.configUseWProofreader,
                        expression: "configUseWProofreader",
                      },
                    ],
                    class: [
                      "dropdown-item",
                      {
                        disabled:
                          !_vm.isWproofReaderActive || !_vm.IncludeAbstract,
                      },
                    ],
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddOpenProofreader("ckPaperAbstract")
                      },
                    },
                  },
                  [_vm._v("Spell Check (Abstract)")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.configUseWProofreader,
                        expression: "configUseWProofreader",
                      },
                    ],
                    class: [
                      "dropdown-item",
                      { disabled: !_vm.isWproofReaderActive },
                    ],
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddOpenProofreader("ckPaperBody")
                      },
                    },
                  },
                  [_vm._v("Spell Check (Body)")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.configUseWProofreader,
                        expression: "configUseWProofreader",
                      },
                    ],
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddToggleProofreader.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isWproofReaderActive,
                            expression: "isWproofReaderActive",
                          },
                        ],
                      },
                      [_vm._v("Disable")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isWproofReaderActive,
                            expression: "!isWproofReaderActive",
                          },
                        ],
                      },
                      [_vm._v("Enable")]
                    ),
                    _vm._v("\n\t\t\t\t\tPERRLA Spellcheck\n\t\t\t\t"),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: [
              "nav-item",
              {
                dropdown: !_vm.isReadOnly,
                "dropdown-hover": _vm.openMenuEnum === _vm.menuEnums.VIEW,
              },
            ],
            on: {
              mouseenter: function ($event) {
                return _vm.mouseEnterMenu(_vm.menuEnums.VIEW)
              },
            },
          },
          [
            _c(
              "a",
              {
                class: [{ show: _vm.openMenuEnum === _vm.menuEnums.VIEW }],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleMenu(_vm.menuEnums.VIEW)
                  },
                },
              },
              [_vm._v("View")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "dropdown-menu",
                  { show: _vm.openMenuEnum === _vm.menuEnums.VIEW },
                ],
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: { to: { name: "EditorReferencesCitations" } },
                  },
                  [_vm._v("\n\t\t\t\t\tReference & Citations\n\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: { to: { name: "EditorResearchNotes" } },
                  },
                  [_vm._v("\n\t\t\t\t\tResearch Notes\n\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: { to: { name: "EditorOutline" } },
                  },
                  [_vm._v("\n\t\t\t\t\tOutline\n\t\t\t\t")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: [
                      "dropdown-item",
                      {
                        disabled:
                          _vm.documentType !==
                          _vm.$enums.DocumentType.RESEARCH_PAPER,
                      },
                    ],
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddOpenPageCountModal.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\tShow Page Count\n\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddToggleFooter.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm.isPaperFooterOn
                      ? _c("span", { key: "spanHideFooter" }, [
                          _vm._v("\n\t\t\t\t\t\tHide\n\t\t\t\t\t"),
                        ])
                      : _c("span", { key: "spanShowFooter" }, [
                          _vm._v("\n\t\t\t\t\t\tShow\n\t\t\t\t\t"),
                        ]),
                    _vm._v("\n\t\t\t\t\tWord Count\n\t\t\t\t"),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: [
              "nav-item",
              {
                dropdown: !_vm.isReadOnly,
                "dropdown-hover": _vm.openMenuEnum === _vm.menuEnums.FORMAT,
              },
            ],
            on: {
              mouseenter: function ($event) {
                return _vm.mouseEnterMenu(_vm.menuEnums.FORMAT)
              },
            },
          },
          [
            _c(
              "a",
              {
                class: [{ show: _vm.openMenuEnum === _vm.menuEnums.FORMAT }],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleMenu(_vm.menuEnums.FORMAT)
                  },
                },
              },
              [_vm._v("Format")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "dropdown-menu",
                  { show: _vm.openMenuEnum === _vm.menuEnums.FORMAT },
                ],
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("bold")
                      },
                    },
                  },
                  [_vm._v("Bold")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("italic")
                      },
                    },
                  },
                  [_vm._v("Italic")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("underline")
                      },
                    },
                  },
                  [_vm._v("Underline")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("strike")
                      },
                    },
                  },
                  [_vm._v("Strikethrough")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("superscript")
                      },
                    },
                  },
                  [_vm._v("Superscript")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("subscript")
                      },
                    },
                  },
                  [_vm._v("Subscript")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("justifyleft")
                      },
                    },
                  },
                  [_vm._v("Align Left")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("justifycenter")
                      },
                    },
                  },
                  [_vm._v("Align Center")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("justifyright")
                      },
                    },
                  },
                  [_vm._v("Align Right")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("removeFormat")
                      },
                    },
                  },
                  [_vm._v("Remove Format")]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: [
              "nav-item",
              {
                dropdown: !_vm.isReadOnly,
                "dropdown-hover": _vm.openMenuEnum === _vm.menuEnums.INSERT,
              },
            ],
            on: {
              mouseenter: function ($event) {
                return _vm.mouseEnterMenu(_vm.menuEnums.INSERT)
              },
            },
          },
          [
            _c(
              "a",
              {
                class: [{ show: _vm.openMenuEnum === _vm.menuEnums.INSERT }],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleMenu(_vm.menuEnums.INSERT)
                  },
                },
              },
              [_vm._v("Insert")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "dropdown-menu",
                  { show: _vm.openMenuEnum === _vm.menuEnums.INSERT },
                ],
              },
              [
                _c(
                  "router-link",
                  {
                    class: [
                      "dropdown-item",
                      {
                        disabled:
                          _vm.documentType !==
                            _vm.$enums.DocumentType.DISCUSSION_POST &&
                          _vm.documentType !==
                            _vm.$enums.DocumentType.RESEARCH_PAPER,
                      },
                    ],
                    attrs: { to: { name: "EditorInsertTable" } },
                  },
                  [_vm._v("\n\t\t\t\t\tTable\n\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    class: [
                      "dropdown-item",
                      {
                        disabled:
                          _vm.documentType !==
                            _vm.$enums.DocumentType.DISCUSSION_POST &&
                          _vm.documentType !==
                            _vm.$enums.DocumentType.RESEARCH_PAPER,
                      },
                    ],
                    attrs: { to: { name: "EditorInsertFigure" } },
                  },
                  [_vm._v("\n\t\t\t\t\tFigure\n\t\t\t\t")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("pagebreak")
                      },
                    },
                  },
                  [_vm._v("Page Break")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddOpenSpecialCharactersModal.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [_vm._v("Special Characters")]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: [
              "nav-item",
              {
                dropdown: !_vm.isReadOnly,
                "dropdown-hover": _vm.openMenuEnum === _vm.menuEnums.REFERENCES,
              },
            ],
            on: {
              mouseenter: function ($event) {
                return _vm.mouseEnterMenu(_vm.menuEnums.REFERENCES)
              },
            },
          },
          [
            _c(
              "a",
              {
                class: [
                  { show: _vm.openMenuEnum === _vm.menuEnums.REFERENCES },
                ],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleMenu(_vm.menuEnums.REFERENCES)
                  },
                },
              },
              [_vm._v("References")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "dropdown-menu",
                  { show: _vm.openMenuEnum === _vm.menuEnums.REFERENCES },
                ],
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: {
                      to: {
                        name: "EditorCreateReferenceStep1",
                        params: { openReferenceTab: "common" },
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\tCreate New Reference\n\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: { to: { name: "EditorAddFromLibrary" } },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\tAdd Reference from My Library\n\t\t\t\t"
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _vm.includeAnnotations
                  ? _c(
                      "a",
                      {
                        key: "aRemoveAnnotations",
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.removeReferenceAnnotationsConfirm.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\tRemove Annotations\n\t\t\t\t")]
                    )
                  : _c(
                      "a",
                      {
                        key: "aAddAnnotations",
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.genericBusEmit("addReferenceAnnotations")
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\tAdd Annotations\n\t\t\t\t")]
                    ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: [
              "nav-item",
              {
                dropdown: !_vm.isReadOnly,
                "dropdown-hover": _vm.openMenuEnum === _vm.menuEnums.TUTORIALS,
              },
            ],
            on: {
              mouseenter: function ($event) {
                return _vm.mouseEnterMenu(_vm.menuEnums.TUTORIALS)
              },
            },
          },
          [
            _c(
              "a",
              {
                class: [{ show: _vm.openMenuEnum === _vm.menuEnums.TUTORIALS }],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleMenu(_vm.menuEnums.TUTORIALS)
                  },
                },
              },
              [_vm._v("Tutorials")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "dropdown-menu",
                  { show: _vm.openMenuEnum === _vm.menuEnums.TUTORIALS },
                ],
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#", id: "FB_EditTitlePage" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeAllMenus.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Edit Title Page")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: [
                      "dropdown-item",
                      {
                        disabled:
                          _vm.documentType !=
                          _vm.$enums.DocumentType.RESEARCH_PAPER,
                      },
                    ],
                    attrs: { href: "#", id: "FB_AddAbstract" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeAllMenus.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Abstracts")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: [
                      "dropdown-item",
                      {
                        disabled:
                          _vm.documentType !=
                          _vm.$enums.DocumentType.RESEARCH_PAPER,
                      },
                    ],
                    attrs: { href: "#", id: "FB_AddTableOfContents" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeAllMenus.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Table of Contents")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: [
                      "dropdown-item",
                      {
                        disabled:
                          _vm.documentType !=
                          _vm.$enums.DocumentType.RESEARCH_PAPER,
                      },
                    ],
                    attrs: { href: "#", id: "FB_AddAppendix" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeAllMenus.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Appendices")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#", id: "FB_CreateNewReference" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeAllMenus.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Create a new Reference")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#", id: "FB_AddReferenceFromMyLibrary" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeAllMenus.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Add Reference from my Library")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#", id: "FB_CreateCitation" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeAllMenus.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Create a Citation")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#", id: "FB_AddHeadingsToMyText" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeAllMenus.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Add Headings to my text")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: [
                      "dropdown-item",
                      {
                        disabled:
                          _vm.documentType !=
                            _vm.$enums.DocumentType.DISCUSSION_POST &&
                          _vm.documentType !=
                            _vm.$enums.DocumentType.RESEARCH_PAPER,
                      },
                    ],
                    attrs: { href: "#", id: "FB_InsertFigure" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeAllMenus.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Insert a Figure")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: [
                      "dropdown-item",
                      {
                        disabled:
                          _vm.documentType !=
                            _vm.$enums.DocumentType.DISCUSSION_POST &&
                          _vm.documentType !=
                            _vm.$enums.DocumentType.RESEARCH_PAPER,
                      },
                    ],
                    attrs: { href: "#", id: "FB_InsertTable" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeAllMenus.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Insert a Table")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#", id: "FB_DownloadMyPaper" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeAllMenus.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Download my paper")]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.showImpersonationMenu
          ? _c(
              "li",
              {
                class: [
                  "nav-item",
                  {
                    dropdown: !_vm.isReadOnly,
                    "dropdown-hover":
                      _vm.openMenuEnum === _vm.menuEnums.DEVELOPER,
                  },
                ],
                on: {
                  mouseenter: function ($event) {
                    return _vm.mouseEnterMenu(_vm.menuEnums.DEVELOPER)
                  },
                },
              },
              [
                _c(
                  "a",
                  {
                    class: [
                      { show: _vm.openMenuEnum === _vm.menuEnums.DEVELOPER },
                    ],
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.toggleMenu(_vm.menuEnums.DEVELOPER)
                      },
                    },
                  },
                  [_vm._v("Developer")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      "dropdown-menu",
                      { show: _vm.openMenuEnum === _vm.menuEnums.DEVELOPER },
                    ],
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.ddShowPaperSource.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Show Paper Source")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.ddToggleShowBlocks.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm.showDevBlocks
                          ? _c("span", { key: "spanHideBlocks" }, [
                              _vm._v("Hide"),
                            ])
                          : _c("span", { key: "spanShowBlocks" }, [
                              _vm._v("Show"),
                            ]),
                        _vm._v("\n\t\t\t\t\tBlocks\n\t\t\t\t"),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.ddOpenDownloadedDocument(
                              _vm.$enums.DownloadPaperContentType.COMPRESSED
                            )
                          },
                        },
                      },
                      [_vm._v("Open PaperContent.txt")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.btnShowInactivityModal.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v("Show Inactivy Modal")]
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("status-dot"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }